import React from 'react'
import Modal from 'react-bootstrap/Modal'

export const ModalView = (props:any) => {
    return (
        <div>
        <Modal show={props.onState}>
        {props.isLoaderVisible ? <div className="overlay" style={{ display: props.isLoaderVisible ? "block" : "none",borderRadius:"10px"}}><div className='custom-spinner-individual'></div></div> : null}
          <Modal.Header className="pb-0 pt-0">
            <Modal.Title>Update Attendance</Modal.Title>
          </Modal.Header>

          <Modal.Body>
          
            <p className="mb-2">Please provide the reason for marking the student as {props.currentState.attendance_value}:<span className='formmandatorycolor'> *</span></p>
            <textarea className="form-control" onChange={props.onChangeValue} name="reason" placeholder="Reason"></textarea>
          </Modal.Body>

          <Modal.Footer className="modelboxbutton">
            <button className="btn btn-danger mr-1 ml-1 w-15"   disabled={props.submitStatus} onClick={props.onConfirm}>Ok</button>
            <button className="btn btn-default mr-1 ml-1 w-15" onClick={props.onCancel}>Cancel</button>
          </Modal.Footer>
        </Modal>
        </div>
    )
}
