import React, { Component } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/BreadCrumb";
import { Link } from 'react-router-dom';
import { Field, Formik, Form } from "formik";
import { Button, FormGroup } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { RouteComponentProps } from 'react-router';
import Rating from '@mui/material/Rating';
import { fetchAssessmentQuestionById, fetchAssessmentStudentListGet, 
    getAssessmentByID, editAssessmentDetails } from "../../store/assessment/Action";
import { stopYearData } from "../../store/profile/Actions";
import SpinnerLoader from "../../components/spinner/SpinnerLoader";

interface OwnPropsParams {
    id: string;
}
interface locationState{
    totalmarks: number;
    uid: number;
    totalMarks: number;
}

export interface Props extends RouteComponentProps<OwnPropsParams> {
    loading?: boolean;
    bluePrintById?: any;
    assessmentById?: any;
    getAssessmentDetails?: any;
    getAssessmentStudents: any;
    getChangeYearData?: any;
    stopYearDate?: any;
    stopYearData: () => any;
    fetchAssessmentQuestionById: (data:any) => any;
    fetchAssessmentStudentListGet: (questionPaperId:any, classId:any) => any;
    getAssessmentByID: (data:any) => any;
    editAssessmentDetails: (data:any) => any;
}

interface StateTypes {
    records?: any;
    grade?: string;
    standard?: string;
    academicYear: any;
    assessmentValues?: any;
    hasMore?: boolean;
    assessmentDetails?: any;
    internalGradeName?: string;
    uid?: number;
    class_id?: any;
    class_name?: any;
    student_id?: any;
    question_paper_id?: number;
    student_name?: any;
    studentList?: any;
    assessmentStudents?: any;
    added_total_marks?:any;
    totalmarks?:any;
    totalMarks?:any;
}

export class AssessmentEdit extends Component<Props, StateTypes> {
    formik: any;
    constructor(props: any) {
        super(props);
        this.state = {
            records: [],
            grade: '',
            academicYear: null,
            assessmentDetails: null,
            internalGradeName: '',
            studentList: [],
            assessmentStudents: [],
            class_name: '',
            added_total_marks: 0,
        }
    }
    componentDidMount(): void {      

        const uid = this.props.match.params.id              
        this.props.getAssessmentByID(uid).then((res:any)=>{
            const { getAssessmentDetails } = this.props;
            const assessmentDetailData ={
                class_id: getAssessmentDetails.class_id,
                class_name: getAssessmentDetails.class_name,
                student_id: getAssessmentDetails.student_id,
                question_paper_id: getAssessmentDetails.question_paper_id,
                student_name: getAssessmentDetails.student_name,
            };
            const securedTotalMarks = Object.values(getAssessmentDetails.assessment_details).reduce(
                (acc: any, curr: any) => acc + (isNaN(curr) ? 0 : parseInt(curr)), 0
            );

            this.props.fetchAssessmentQuestionById(assessmentDetailData.question_paper_id).then((res: any) => {
                const { assessmentById } = this.props;
                const total_Marks = assessmentById.total_marks;
                const order: Record<string, number> = {
                        "MCQ": 1,
                        "FIB": 2,
                        "Regular-2 Marks": 3,
                        "Regular-5 Marks": 4,
                        "Regular-10 Marks": 5
                    };
                    const sortedQuestions = assessmentById.question_details.sort((a: any, b: any) => {
                        return order[a.db_question_type] - order[b.db_question_type];
                    });
                    const initializedData = sortedQuestions.map((group:any) => ({
                        ...group,
                        question: group.question.map((q:any) => ({
                          ...q,
                          currentRating: getAssessmentDetails.assessment_details[q.uid], // Default rating set to 0
                        })),
                      }));
            
                    this.setState({assessmentValues:assessmentById, records: initializedData,totalMarks:total_Marks});
            }) 
            this.setState({
                class_id: assessmentDetailData.class_id,
                class_name: assessmentDetailData.class_name,
                student_id: assessmentDetailData.student_id,
                question_paper_id: assessmentDetailData.question_paper_id,
                student_name: assessmentDetailData.student_name,
                added_total_marks: securedTotalMarks
            })
        });
        this.getCurrectYear();
        window.scrollTo(0, 0);
    }

    componentWillUpdate(nextProps: any, nextState: any) {
        const { stopYearDate } = this.props;
        if (stopYearDate === true) {
            this.getCurrectYear(this.props.getChangeYearData);
        }
    }
    componentDidUpdate() {
        const { stopYearDate } = this.props;
        if (stopYearDate === true) {
            this.props.stopYearData();
        }
    }

    getCurrectYear(getYear?: any) {
        if (getYear && getYear !== undefined && getYear !== null) {
            this.getBluePrintDetails(getYear);
            this.setState({ academicYear: getYear });
        } else {
            this.getBluePrintDetails(this.props.getChangeYearData);
            this.setState({ academicYear: this.props.getChangeYearData });
        }
    }

    getBluePrintDetails(getYear?: any) {
        const getData = {
            academic_year: getYear,
        }
    }


    handleRating(rate:any, id:any) {
    const updatedQuestions = this.state.records.map((question:any) => ({
        ...question,
        question: question.question.map((q:any) => {
          if (q.uid === id) {
            return { ...q, currentRating: rate !== null ? rate : 0 };
          }
          return q;
        }),
      }));
      this.setState({records: updatedQuestions})
      this.calculateTotalMarks(updatedQuestions); 
    }

    calculateTotalMarks(records:any){
        let totalmarks = 0;
        records.forEach((item:any) => {
          item.question.forEach((ques:any) => {
            totalmarks += ques.currentRating || 0;
          })  
        });
        this.setState({added_total_marks: totalmarks});
    }

    toRoman(num:any) {
        const roman = [
          { value: 1000, symbol: "M" },
          { value: 900, symbol: "CM" },
          { value: 500, symbol: "D" },
          { value: 400, symbol: "CD" },
          { value: 100, symbol: "C" },
          { value: 90, symbol: "XC" },
          { value: 50, symbol: "L" },
          { value: 40, symbol: "XL" },
          { value: 10, symbol: "X" },
          { value: 9, symbol: "IX" },
          { value: 5, symbol: "V" },
          { value: 4, symbol: "IV" },
          { value: 1, symbol: "I" },
        ];
      
        let result = "";
        for (let i = 0; i < roman.length; i++) {
          while (num >= roman[i].value) {
            result += roman[i].symbol;
            num -= roman[i].value;
          }
        }
        return result.toLowerCase(); // Convert to lowercase for i, ii, iii...
      }
      

    private renderQuestionSectionView(records: any) {
        let index =1;
        return (
            <div>
                {records && records.length > 0 ?
                    records.map((items: any) => (
                        <div className="question_section">
                            <div className="question_section_title">
                                <div>
                                    {items.question_type_desc}
                                </div>
                                <div>
                                    {items.marks}
                                </div>
                            </div>
                            {items.question.length > 0 && items.question !== undefined ?
                                items.question.map((ques: any) => (
                                    <>
                                        {
                                            <div className="questions_block">
                                                <div className="question_name">{index ++}.    {ques.question}</div >
                                                <div className="question_image">
                                                    {ques.question_image ?
                                                        <img src={ques.question_image} width={200} height={200}></img>
                                                        : null}
                                                </div>
                                                {
                                                    ques.sub_question_list ? (
                                                        <>
                                                            {ques.sub_question_list.length > 0 ? (
                                                                ques.sub_question_list.map((option: any, index: any) => (
                                                                    <>
                                                                        <div className="mt-4">{this.toRoman(index + 1)}.  {option.question}</div>
                                                                        {option.question_image ? <div className="question_image">
                                                                            <img src={option.question_image} width={200} height={200}></img>
                                                                        </div > : null}
                                                                    </>
                                                                ))
                                                            ) : null}
                                                        </>) : null
                                                }
                                                <div className="text-right mb-3 mr-2 mt-4">                                                    
                                                   
                                                     <Rating name="customized-10" 
                                                    defaultValue={ques.currentRating} 
                                                    max={items.question_type_marks} 
                                                    precision={0.5}
                                                    size="large"
                                                    onChange={(event, newValue) => {
                                                        this.handleRating(newValue,ques.uid)                                                          
                                                         //  console.log(newValue)
                                                        }}/>
                                                </div>
                                            </div>
                                        }
                                    </>
                                ))
                                : null}
                        </div >

                    ))
                    : null}
            </div >)
    }

    render() {
        const { records, class_id,class_name,student_id,question_paper_id, studentList,student_name,added_total_marks, totalMarks } = this.state;
        const { loading } = this.props;
        const loadingQuestionFull = { display: loading ? "block" : "none" };

        const initialValues = {
            class_id: class_id,
            class_name: class_name,
            student_id: student_id,
            student_name: student_name,
            question_paper_id: question_paper_id,
            added_total_marks: added_total_marks
        }
        return (
            <div className="page-wrapper">
                <div className="page-content-wrapper">
                    <div className="page-content pt-3">
                        <BreadCrumb
                            titleName={['Assessment Edit']}
                            homeName={['Home']}
                            url={['dashboard']}
                            mainPageTitle={['Assessment Edit']} />
                        {class_id && student_name && question_paper_id ? 
                        <div className="row">
                            <div className="col-md-12">
                                <div>
                                    <Formik
                                        ref={node => this.formik = node}
                                        initialValues={initialValues}
                                        onSubmit={(values, {setSubmitting}) => {   
                                            const finalData :any = {};

                                            this.state.records.forEach((group:any) => {
                                              group.question.forEach((item:any) => {
                                                finalData[item.uid] = item.currentRating;
                                              });
                                            });
                                            const getEditAssessmentValues: any ={
                                                uid: this.props.match.params.id,
                                                class_id: values.class_id,
                                                student_id: values.student_id,
                                                question_paper_id: question_paper_id,
                                                assessment_details: finalData
                                            };
                                            this.props.editAssessmentDetails(getEditAssessmentValues).then((res:any)=> {

                                            });                                        
                                        }}
                                        >
                                        {({ values, errors, isSubmitting, isValidating, handleChange, dirty, touched, setFieldValue }) => (
                                            <Form>
                                                <div className="row">
                                                <div className="col-md-4 col-sm-4 col-4">
                                                <FormGroup>
                                                        <Field
                                                        label={<><span>Class</span><span className="formmandatorycolor"> *</span></>}
                                                        name="internalGradeId"
                                                        component={TextField}
                                                        disabled={true}  
                                                        value={class_name}>                                                            
                                                        </Field>
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-4 col-sm-4 col-4">
                                                    <FormGroup>
                                                        <Field
                                                            label={<><span>Student</span><span className='formmandatorycolor'> *</span></>}
                                                            name="student_id"
                                                            component={TextField}
                                                            disabled={true}  
                                                            value={student_name}                                                          
                                                            >
                                                        </Field>
                                                    </FormGroup>
                                                </div>                                                
                                                </div>
                                                <div className="card-box mb-3 mr-2 mt-5">
                                                    <div className="card-head">
                                                        <header>Assessment Entry</header>
                                                    </div>
                                                    <div className="card-body">
                                                        <div>
                                                            {this.renderQuestionSectionView(records)}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-right mb-3 mr-2 mt-4">
                                                    <div className="font-weight-bold mb-3 mr-2 mt-4">Total Marks : {added_total_marks} / {totalMarks}</div>
                                                    <Link to={"/assessment"}>
                                                        <Button className="btn btn-pink mr-1 ml-1">
                                                            Cancel
                                                        </Button>
                                                    </Link>
                                                        <Button className="btn btn-pink mr-1 ml-1" type="submit">
                                                            Submit
                                                        </Button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                                
                            </div>
                        </div>
                        : <div><SpinnerLoader/></div>}                        
                    </div>
                </div>
                <div style={loadingQuestionFull}><SpinnerLoader /></div>
            </div>
        )
    }
}



const mapStateToProps = (state: any) => {
    return {
        loading: state.Assessment.loading,
        getYear: state.profile.getYear,
        stopYear: state.profile.yearStatusFails,
        getChangeYearData: state.profile.getYear,
        bluePrintById: state.QuestionBank.bluePrintById,
        getStudentDetails:state.student.getStudentNameList,
        assessmentById: state.Assessment.assessmentById,
        getAssessmentStudents: state.Assessment.getAssessmentStudents,
        getAssessmentDetails: state.Assessment.getAssessmentDetails,
    }
}

export default connect(mapStateToProps, {
    stopYearData,
    fetchAssessmentQuestionById,
    fetchAssessmentStudentListGet,
    getAssessmentByID,
    editAssessmentDetails,
})(AssessmentEdit)