export interface ReportState{
    loading: boolean;
    errors: any;
    records: any;
    items: any,
    page: number,
    per_page: number,
    total: number,
    isAuthenticated: boolean | null; 
    teacherDropdown:any,
    subjectDropdown:any,
    subjectAnalysisReport: any,
    teacherPerformanceReport: any,
    teacherAttendaceReport:any,
    classAttendanceReport:any,
    subjectPerformanceReport:any,
    subjectAttendivenessReport:any,
    teacherOverallReport:any,
    subjectExamReport:any,
    page_Teacher_Engagement: number,
    per_page_Teacher_Engagement: number,
    records_Teacher_Engagement: any,
    total_Teacher_Engagement: number,
    page_Teacher_Performance: number,
    per_page_Teacher_Performance: number,
    records_Teacher_Performance: any,
    total_Teacher_Performance: number,
}

export enum ReportTypes{
    TEACHER_DROPDOWN = 'TEACHER_DROPDOWN',
    TEACHER_DROPDOWN_SUCCESS = 'TEACHER_DROPDOWN_SUCCESS',
    TEACHER_DROPDOWN_FAIL = 'TEACHER_DROPDOWN_FAIL',
    SUBJECT_DROPDOWN = 'SUBJECT_DROPDOWN',
    SUBJECT_DROPDOWN_SUCCESS = 'SUBJECT_DROPDOWN_SUCCESS',
    SUBJECT_DROPDOWN_FAIL = 'SUBJECT_DROPDOWN_FAIL',
    SUBJECT_ANALYSIS_REPORT = 'SUBJECT_ANALYSIS_REPORT',
    SUBJECT_ANALYSIS_REPORT_SUCCESS = 'SUBJECT_ANALYSIS_REPORT_SUCCESS',
    SUBJECT_ANALYSIS_REPORT_FAIL = 'SUBJECT_ANALYSIS_REPORT_FAIL',
    TEACHER_ENGAGEMENT_REPORT = 'TEACHER_ENGAGEMENT_REPORT',
    TEACHER_ENGAGEMENT_REPORT_SUCCESS = 'TEACHER_ENGAGEMENT_REPORT_SUCCESS',
    TEACHER_ENGAGEMENT_REPORT_FAIL = 'TEACHER_ENGAGEMENT_REPORT_FAIL',
    TEACHER_PERFORMANCE_REPORT = 'TEACHER_PERFORMANCE_REPORT',
    TEACHER_PERFORMANCE_REPORT_SUCCESS = 'TEACHER_PERFORMANCE_REPORT_SUCCESS',
    TEACHER_PERFORMANCE_REPORT_FAIL = 'TEACHER_PERFORMANCE_REPORT_FAIL',
    TEACHER_ATTENDANCE_REPORT = 'TEACHER_ATTENDANCE_REPORT',
    TEACHER_ATTENDANCE_REPORT_SUCCESS = 'TEACHER_ATTENDANCE_REPORT_SUCCESS',
    TEACHER_ATTENDANCE_REPORT_FAIL = 'TEACHER_ATTENDANCE_REPORT_FAIL',
    CLASS_ATTENDANCE_REPORT = 'CLASS_ATTENDANCE_REPORT',
    CLASS_ATTENDANCE_REPORT_SUCCESS = 'CLASS_ATTENDANCE_REPORT_SUCCESS',
    CLASS_ATTENDANCE_REPORT_FAIL = 'CLASS_ATTENDANCE_REPORT_FAIL',
    SUBJECT_PERFORMANCE_REPORT = 'SUBJECT_PERFORMANCE_REPORT',
    SUBJECT_PERFORMANCE_REPORT_SUCCESS = 'SUBJECT_PERFORMANCE_REPORT_SUCCESS',
    SUBJECT_PERFORMANCE_REPORT_FAIL = 'SUBJECT_PERFORMANCE_REPORT_FAIL',
    SUBJECT_ATTENDIVENESS_REPORT = 'SUBJECT_ATTENDIVENESS_REPORT',
    SUBJECT_ATTENDIVENESS_REPORT_SUCCESS = 'SUBJECT_ATTENDIVENESS_REPORT_SUCCESS',
    SUBJECT_ATTENDIVENESS_REPORT_FAIL = 'SUBJECT_ATTENDIVENESS_REPORT_FAIL',
    TEACHER_OVERALL_REPORT = 'TEACHER_OVERALL_REPORT',
    TEACHER_OVERALL_REPORT_SUCCESS = 'TEACHER_OVERALL_REPORT_SUCCESS',
    TEACHER_OVERALL_REPORT_FAIL = 'TEACHER_OVERALL_REPORT_FAIL',
    SUBJECT_EXAM_REPORT = 'SUBJECT_EXAM_REPORT',
    SUBJECT_EXAM_REPORT_SUCCESS = 'SUBJECT_EXAM_REPORT_SUCCESS',
    SUBJECT_EXAM_REPORT_FAIL = 'SUBJECT_EXAM_REPORT_SUCCESS',
}