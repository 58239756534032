import React from 'react';
import { connect } from 'react-redux';
import { Field, Formik, Form, ErrorMessage } from 'formik';
import { SchoolFieldsType } from '../../store/school/Types'
import { Button, FormGroup, MenuItem } from '@material-ui/core';
import { MONTHLIST, commonKeys } from '../../services/Constants'
import { addSchool, commonGet } from '../../store/school/Actions';
import BreadCrumb from '../../components/BreadCrumb'
import history from '../../History';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { TextField } from 'formik-material-ui';
import SpinnerLoader from '../../components/spinner/SpinnerLoader'
import { RootState } from '../../store/Index'
import { SchoolValidation } from './SchoolValidation'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { containerStyle, center, position } from './Types';
import { SCHOOLMANAGE } from '../../services/Config';
import '../../customtext.css'
import { number } from 'yup';
import { SchoolImageUpload } from './SchoolImageUpload';
import { ModelImageView } from '../../components/ModelImageView';

const initialSchoolValues: SchoolFieldsType = {
    school_name: '',
    address: '',
    name: '',
    designation: '',
    phone_number: '',
    email_id: '',
    category: '',
    latitude: '',
    longitude: '',
    acadamic_start_month: '',
    acadamic_end_month: '',
    start_time: '',
    end_time: '',
    school_logo: [],
    school_coverage_area: 0,
}

export type OwnSchoolFormProps = {
    addSchool: (school: any) => any;
    commonGet: (URL: any, body: any) => any;
    loading: boolean;
    errorMessage: any;
    commonCategoryList: any
};
interface propsSchoolTyps {
    latitudeAdd: any,
    longitudeAdd: any,
    schoolImage: any,
    image?: any;
    showImageView?: any;
}
class AddNewSchool extends React.Component<OwnSchoolFormProps, propsSchoolTyps> {
    formik: any;
    constructor(props: any) {
        super(props);
        this.state = {
            latitudeAdd: 13.067439,
            longitudeAdd: 80.237617,
            schoolImage: [],
            image: "",
            showImageView: false,
        }
    }
    componentDidMount(): void {
        window.scrollTo(0, 0);
        this.getCategoryList()
    }
    getCategoryList() {
        this.props.commonGet(SCHOOLMANAGE.commonListItem, commonKeys.schoolCategory)
    }
    setImagesState = (setImages: any) => {
        this.setState({ schoolImage: setImages });
    }
    showImage = (image: any) => {
        this.setState({ image: image, showImageView: true });
    }
    onClose = () => {
        this.setState({ showImageView: false });
    }
    public onMarkerDragEnd = ({ e }: { e: any }) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        this.setState({
            latitudeAdd: lat,
            longitudeAdd: lng,
        })
    };
    componentDidUpdate() {
        if (this.props.errorMessage && this.props.errorMessage !== null && this.props.errorMessage !== undefined) {
            this.formik.setFieldError("school_name", this.props.errorMessage.school_name);
            this.formik.setFieldError("category", this.props.errorMessage.category);
            this.formik.setFieldError("name", this.props.errorMessage.name);
            this.formik.setFieldError("designation", this.props.errorMessage.designation);
            this.formik.setFieldError("phone_number", this.props.errorMessage.phone_number);
            this.formik.setFieldError("email_id", this.props.errorMessage.email_id);
            this.formik.setFieldError("address", this.props.errorMessage.address);
            this.formik.setFieldError("start_time", this.props.errorMessage.start_time);
            this.formik.setFieldError("end_time", this.props.errorMessage.end_time);
            this.formik.setFieldError("school_coverage_area", this.props.errorMessage.school_coverage_area);
            this.formik.setFieldError("acadamic_start_month", this.props.errorMessage.acadamic_start_month);
            this.formik.setFieldError("acadamic_end_month", this.props.errorMessage.acadamic_end_month);
        }
    }

    // Synchronous validation
    render() {
        const { loading, commonCategoryList } = this.props;
        const { latitudeAdd, longitudeAdd, schoolImage, showImageView, image } = this.state;
        const loadingTextCSS = { display: loading ? "block" : "none" };
        const getToken = localStorage.getItem('token');
        const lat = this.state ? latitudeAdd : '';
        const lng = this.state ? longitudeAdd : '';
        const MonthList: any = MONTHLIST;
        if (!getToken) {
            history.push("/");
        }
        return (
            <div>
                <ModelImageView
                onState={showImageView}
                onCancel={this.onClose}
                image={image}
                />
                <div className="page-wrapper">
                    <div className="page-content-wrapper">
                        <div className="page-content pt-3">
                            <BreadCrumb
                                titleName={['School']}
                                homeName={['Home']}
                                url={['dashboard']}
                                mainPageTitle={['Add School']}
                                baseName={['School']}
                                baseURL={['school']} />
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card-box">
                                        <div className="card-head">
                                            <header>Add School</header>
                                        </div>
                                        <div className="card-body">
                                            <Formik
                                                ref={node => this.formik = node}
                                                validationSchema={SchoolValidation}
                                                initialValues={initialSchoolValues}

                                                onSubmit={(values, actions) => {
                                                    const startTime = values.start_time;
                                                    const endTime = values.end_time;

                                                    const initialValuesList: any = {
                                                        school_name: values.school_name,
                                                        address: values.address,
                                                        contact_persons: [{
                                                            name: values.name,
                                                            designation: values.designation,
                                                            phone_number: values.phone_number,
                                                            email_id: values.email_id.toLowerCase()
                                                        }],
                                                        category: values.category,
                                                        school_coordinates: [{
                                                            latitude: lat,
                                                            longitude: lng
                                                        }],
                                                        acadamic_start_month: values.acadamic_start_month,
                                                        acadamic_end_month: values.acadamic_end_month,
                                                        start_time: moment(startTime, 'hh:mm').format('hh:mm A'),
                                                        end_time: moment(endTime, 'hh:mm').format('hh:mm A'),
                                                        coverage_area: values.school_coverage_area,
                                                        school_logo: values.school_logo.length > 0 ? values.school_logo[0].data_url : ""
                                                    }
                                                    this.props.addSchool(initialValuesList)
                                                }}>
                                                {({ values, errors, isSubmitting, isValidating, dirty, touched, handleBlur, setFieldValue }) => {
                                                    const isSchoolEmpty = (!values.school_name
                                                        || !values.category
                                                        || !values.name
                                                        || !values.phone_number
                                                        || !values.email_id
                                                        || !values.designation
                                                        || !values.address
                                                        || !values.start_time
                                                        || !values.end_time
                                                        || !values.school_coverage_area
                                                        || !values.acadamic_start_month
                                                        || !values.acadamic_end_month);
                                                    return (
                                                        <Form >
                                                            <div>
                                                                <div className="">
                                                                    <div className="row">
                                                                        <div className="col-md-6 p-t-20">
                                                                            <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                <FormGroup>
                                                                                    <Field

                                                                                        label={<><span>School Name</span><span className='formmandatorycolor'> *</span></>}
                                                                                        type="text"
                                                                                        name="school_name"
                                                                                        component={TextField}
                                                                                        className="textfield__input"
                                                                                        disabled={false}
                                                                                    />

                                                                                </FormGroup>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 p-t-20">
                                                                            <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                {commonCategoryList ?
                                                                                    <FormGroup>
                                                                                        <Field
                                                                                            label={<><span>Category</span><span className='formmandatorycolor'> *</span></>}
                                                                                            name="category"
                                                                                            select
                                                                                            component={TextField}
                                                                                            className="textfield__input"
                                                                                            fullwidth="true"
                                                                                            disabled={false}
                                                                                        >
                                                                                            {commonCategoryList.map((item: any) => (
                                                                                                <MenuItem value={item.value}>{item.value}</MenuItem>
                                                                                            ))}
                                                                                        </Field>
                                                                                    </FormGroup>
                                                                                    : null}

                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-md-6 p-t-20">
                                                                            <div>
                                                                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                    <FormGroup>
                                                                                        <Field
                                                                                            label={<><span>Contact Person Name</span><span className='formmandatorycolor'> *</span></>}
                                                                                            type="text"
                                                                                            name="name"
                                                                                            component={TextField}
                                                                                            className="textfield__input"
                                                                                            fullwidth="true"
                                                                                            disabled={false}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 p-t-20">
                                                                            <div>
                                                                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                    <FormGroup>
                                                                                        <Field
                                                                                            label={<><span>Contact Person Designation</span><span className='formmandatorycolor'> *</span></>}
                                                                                            type="text"
                                                                                            name="designation"
                                                                                            component={TextField}
                                                                                            className="textfield__input"
                                                                                            fullwidth="true"
                                                                                            disabled={false}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-md-6 p-t-20">
                                                                            <div>
                                                                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                    <FormGroup>
                                                                                        <Field
                                                                                            label={<><span>Contact Person Phone Number</span><span className='formmandatorycolor'> *</span></>}
                                                                                            type="text"
                                                                                            name="phone_number"
                                                                                            component={TextField}
                                                                                            className="textfield__input"
                                                                                            fullwidth="true"
                                                                                            disabled={false}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 p-t-20">
                                                                            <div>
                                                                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                    <FormGroup>
                                                                                        <Field
                                                                                            label={<><span>Contact Person Email Id</span><span className='formmandatorycolor'> *</span></>}
                                                                                            type="email"
                                                                                            name="email_id"
                                                                                            component={TextField}
                                                                                            className="textfield__input"
                                                                                            fullwidth="true"
                                                                                            disabled={false}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-12 p-b-5 p-t-5">
                                                                            <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                                                                <FormGroup>
                                                                                    <Field
                                                                                        name="address"
                                                                                        component={TextField}
                                                                                        label={<><span>Address</span><span className='formmandatorycolor'> *</span></>}
                                                                                        className="textfield__input"
                                                                                        rows="1"
                                                                                        disabled={false}
                                                                                    />
                                                                                </FormGroup>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-4 p-t-20">
                                                                            <div>
                                                                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                    <FormGroup>
                                                                                        <Field
                                                                                            type="time"
                                                                                            name="start_time"
                                                                                            label={<><span>Start Time</span><span className='formmandatorycolor'> *</span></>}
                                                                                            component={TextField}
                                                                                            className="textfield__input"
                                                                                            fullwidth="true"
                                                                                            disabled={false}
                                                                                            InputLabelProps={{
                                                                                                shrink: true,
                                                                                            }}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4 p-t-20">
                                                                            <div>
                                                                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                    <FormGroup>
                                                                                        <Field
                                                                                            type="time"
                                                                                            name="end_time"
                                                                                            label={<><span>End Time</span><span className='formmandatorycolor'> *</span></>}
                                                                                            component={TextField}
                                                                                            className="textfield__input"
                                                                                            fullwidth="true"
                                                                                            disabled={false}
                                                                                            InputLabelProps={{
                                                                                                shrink: true,
                                                                                            }}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4 p-t-20">
                                                                            <div>
                                                                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                    <FormGroup>
                                                                                        <Field
                                                                                            type="text"
                                                                                            name="school_coverage_area"
                                                                                            label={<><span>School Coverage Area(In Meters)</span><span className='formmandatorycolor'> *</span></>}
                                                                                            component={TextField}
                                                                                            className="textfield__input"
                                                                                            fullwidth="true"
                                                                                            disabled={false}
                                                                                            InputLabelProps={{
                                                                                                shrink: true,
                                                                                            }}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-6 p-t-20">
                                                                            <div>
                                                                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                    <FormGroup>
                                                                                        <Field
                                                                                            name="acadamic_start_month"
                                                                                            select
                                                                                            component={TextField}
                                                                                            className="textfield__input"
                                                                                            label={<><span>Academic Start Month</span><span className='formmandatorycolor'> *</span></>}
                                                                                            fullwidth="true"
                                                                                            disabled={false}
                                                                                            onChange={(e: any, value: any) => {
                                                                                                setFieldValue('acadamic_start_month', e.target.value)
                                                                                                const currentMonthIndex = MONTHLIST.findIndex(month => month.code === e.target.value);
                                                                                                let nextMonthCode = ''
                                                                                                if (currentMonthIndex !== -1) {
                                                                                                    const nextMonthIndex = (currentMonthIndex + 11) % MONTHLIST.length;
                                                                                                    nextMonthCode = MONTHLIST[nextMonthIndex].code;
                                                                                                }
                                                                                                setFieldValue('acadamic_end_month', nextMonthCode)
                                                                                            }}
                                                                                        >
                                                                                            {MonthList.map((item: any) => (
                                                                                                <MenuItem value={item.code}>{item.code}</MenuItem>
                                                                                            ))}
                                                                                        </Field>
                                                                                    </FormGroup>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 p-t-20">
                                                                            <div>
                                                                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                    <FormGroup>
                                                                                        <Field
                                                                                            name="acadamic_end_month"
                                                                                            select
                                                                                            component={TextField}
                                                                                            className="textfield__input"
                                                                                            label={<><span>Academic End Month</span><span className='formmandatorycolor'> *</span></>}
                                                                                            fullwidth="true"
                                                                                            disabled={true}
                                                                                        >
                                                                                            {MonthList.map((item: any) => (
                                                                                                <MenuItem value={item.code}
                                                                                                    disabled={item.code === values.acadamic_start_month}>{item.code}</MenuItem>
                                                                                            ))}
                                                                                        </Field>
                                                                                    </FormGroup>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-6 p-t-20">
                                                                            <FormGroup>
                                                                                <legend className="MuiFormLabel-root ml-2">
                                                                                    <span>School Logo</span>
                                                                                </legend>
                                                                            </FormGroup>
                                                                            <div className="p-t-20">
                                                                                <SchoolImageUpload setImages={schoolImage} setImagesState={this.setImagesState} setfield={setFieldValue} clickImage={this.showImage} />
                                                                                {values.school_logo.map((logo:any, index:any) => (
                                                                                        <div key={index}>
                                                                                            <ErrorMessage
                                                                                                name={`school_logo[${index}].data_url`}
                                                                                                component="div"
                                                                                                className="errorcolor"
                                                                                            />
                                                                                        </div>
                                                                                    ))}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mt-3 mb-3">
                                                                        <LoadScript googleMapsApiKey="AIzaSyA3yhFDKARfSmU95yaMMQ3SbsW1kOX_gsA">
                                                                            <GoogleMap
                                                                                mapContainerStyle={containerStyle}
                                                                                center={center}
                                                                                zoom={15}
                                                                            >
                                                                                <Marker
                                                                                    position={position}
                                                                                    onDragEnd={(e) => this.onMarkerDragEnd({ e: e })}
                                                                                    draggable={true}
                                                                                />
                                                                                <></>
                                                                            </GoogleMap>
                                                                        </LoadScript>
                                                                    </div>
                                                                    <div className="text-right mb-3 mr-2 mt-4">
                                                                        <Button className="btn btn-pink mr-1 ml-1 w-15 mt-1"
                                                                            disabled={isSchoolEmpty || isValidating
                                                                                || !dirty || !!(errors.acadamic_end_month && touched.acadamic_end_month)
                                                                                || !!(errors.acadamic_start_month && touched.acadamic_start_month) ||
                                                                                !!(errors.end_time && touched.end_time) ||
                                                                                !!(errors.phone_number && touched.phone_number) ||
                                                                                !!(errors.email_id && touched.email_id) ||
                                                                                !!(errors.start_time && touched.start_time) ||
                                                                                !!(errors.address && touched.address) ||
                                                                                !!(errors.email_id && touched.email_id) ||
                                                                                !!(errors.phone_number && touched.phone_number) ||
                                                                                !!(errors.designation && touched.designation) ||
                                                                                !!(errors.name && touched.name) ||
                                                                                !!(errors.category && touched.category) ||
                                                                                !!(errors.school_coverage_area && touched.school_coverage_area) ||
                                                                                !!(errors.school_name && touched.school_name)} type="submit">Submit</Button>
                                                                        <Link to="/school"><Button className="btn btn-default mr-1 ml-1 w-15">Cancel</Button>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Form>
                                                    )
                                                }
                                                }
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={loadingTextCSS}><SpinnerLoader /></div>
            </div>
        );
    }
}

const mapStateToProps = ({ schools }: RootState) => {
    return {
        loading: schools.loading,
        errorMessage: schools.errors,
        commonCategoryList: schools.schoolCategoryList
    };
};

export default connect(
    mapStateToProps, { addSchool, commonGet }
)(AddNewSchool);
