import AddControlPanel from '../modules/HomeStudy/AddControlPanel';
import { UserRoles } from '../services/Constants'
//some views will be for admins only, some for users (non-admins)
// and then the rest is available for all roles
const userRoles = {
    admins: [String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin)],
    other: [String(UserRoles.teacher), String(UserRoles.parent)],
    users: [String(UserRoles.nonAdmin)],
    all: [
        String(UserRoles.acadamicAdmin),
        String(UserRoles.schoolAdmin),
        String(UserRoles.teacher),
        String(UserRoles.parent),
        String(UserRoles.nonAdmin)
    ]
};

export enum AuthRoutes {
    dashboard = '/dashboard',
    viewschooldetails = '/school',
    viewsubjectdetails = '/subject',
    teacherengagement = '/teacherengagement',
    addnewschool = '/add_school',
    editschool = '/edit_school/:id',
    uploadSchoolLogo = '/upload_school_logo',
    logout = '/logout',
    addSubject = '/add_subject',
    editSubject = '/edit_subject/:id',
    viewSchool = '/view_school',
    teacherAttendance = '/teacher_attendance'
}

export enum SchoolManage {
    viewclasses = '/class',
    addclasses = '/add_class',
    editclasses = '/edit_class/:id'
}

export enum UserManagementUrl {
    userManageView = '/user',
    addUserManage = '/add_user',
    editUserManage = '/edit_user/:id'
}

export enum NonAuthRoutes {
    login = '/',
    forgotpassword = '/forgot_password',
    resetpassword = '/reset_password',
    unautheration = '/UnAuthorized',
    loginUser = '/login',
    userloginpassword = '/userloginpassword',
    userregister = '/register',
    userrotp = '/otp',
    userregisterpassword = '/password',
    ForgotOTP = '/forgot_otp',
    pageNoFound = '/404',
    ChangePassword = '/change_password'
}

export enum StudentDetails {
    studentView = '/student',
    studentAdd = '/student_add',
    studentEdit = '/student_edit/:id',
    studentTransfer = '/student_transfer/:id',
    studnetFullDetails = '/student_profile/:id'
}

export enum TeacherDetails {
    teacherView = '/teacher',
    teacherAdd = '/teacher_add',
    teacherEdit = '/teacher_edit/:id',
    teacherFullDetails = '/teacher/:id',
    addSubject = '/map_subject/',
    AddClassInCharge = '/class_incharge_add',
    TeacherClassEdit = "/class_incharge/edit",
    TeacherSubjectEdit = "/subject_mapping",
    TeacherEngagement = "/teachers/:id",
    TeacherProfile = "/teachers_profile"
}

export enum DiaryList {
    diaryView = '/diary',
    diaryAdd = '/diary_add',
    diaryEdit = '/diary_edit/:id'
}
export enum ARTagDownload {
    ARTag = '/ar_tag'
}

export enum HelpDownload {
    HelpDownload = '/help_download'
}

export enum DemoDel {
    Demodelete = '/demo_delete'


}

export enum NoticeBoard {
    noticeView = '/notice_board'
}

export enum Question {
    ViewQuestion = '/question',
    AddQuestion = '/question/add',
    ViewQuestionlist = '/question/:id',
    AddMoreQuestion = '/question/add/:id',
    studentQuestionView = '/student/:id',
    viewResponses = '/view_responses',
    UploadQuestionSet = '/uploadquestion',
    ImportQuestion = '/importquestion',
}

export enum Pscyometric {
    ViewPscyometric = '/psychometric',
    AddPscyometric = '/psychometric/add',
    EditPscyometric = '/psychometric/:id',
    StudentListPsychometricReport = '/psychometric/studentlist/:id',
    StudentPsychometricReport = '/psychometric_student/:id',
}

export enum QuestionBank {
    BluePrintGrid = '/blueprint',
    AddBluePrint = '/blueprint/add',
    EditBluePrint = '/blueprint/edit/:id',
    ViewBluePrint = '/viewblueprint/:id',
    GenerateQuestions = '/generatequestions',
    ViewGenerateQuestions = '/viewgeneratequestions',
    QuestionBankGrid = '/questionpaper',
    ViewQuestionPaper = '/viewquestionpaper/:id',
    ExamType = "/examtype",
    AddExamType = "/examtype/add",
    EditExamType = "/examtype/edit/:id",
    SubjectMappingGrid = '/subjectmapping',
    AddSubjectMapping = '/subjectmapping/add',
    EditSubjectMapping = '/subjectmapping/edit/:id',
    FeedbackQuestionsGrid = '/feedbackquestion',
    AddFeedbackQuestions = '/feedbackquestion/add',
    EditFeedbackQuestions = '/feedbackquestion/edit/:id',
    Assessment = '/assessment',
    AssessmentCreate = '/assessment/create',
    AssessmentEdit = '/assessment/edit/:id',
}

export enum ClassesMenu {
    viewClasses = '/classes'
}

export enum commonmenu {
    InstantFeedback = '/instant_feedback',
    ViewInstantFeedback = '/view_instant_feedback/:id',
    Attendance = '/attendance',
    StudentView = '/student_view',
    ClassesReport = '/classes_report/:id',
    Organizations = '/institutions',
    viewSchoolTeam = '/institutions/:id',
    Quizzes = '/quizzes',
    getClassId = '/classes/:id',
    getQuizzesId = '/quizzes/:id',
    getInstantFeedback = '/instant_feedback/:id',
    getQuizzesReport = '/quizzes_report/:id',
    updateStudentAttendance = '/update_student_attendance'
}

export enum FeedBackMenu {
    viewFeedBack = '/topics/:id'
}

export enum LicensePartitionMenu {
    licensePartition = '/licensepartition',
    addLicensePartition = '/licensepartition/add',
    editLicensePartition = '/licensepartition/:id',
}

export enum StudentPromotion {
    StudentPromotion= "/student-promotion"
}

export enum ReportView {
    Report = '/report',
    StudentReport = '/studentreport'
}

export enum SchoolCalendar {
    SchoolCalendar = '/school-calendar',
    UploadSchoolCalendar = '/upload-school-calendar',
}

export enum HomeStudy {
    ControlPanelGrid = "/control-panel",
    AddControlPanel = "/control-panel/add",
    EditControlPanel = "/control-panel/edit/:id",
    HomeStudyView = "/homestudy-view"
}

export default userRoles;