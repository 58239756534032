export const optionDoughnut = {
  legend: {
      display: false
    },
  tooltips: {
    callbacks: {
      label: function(tooltipItem:any, data:any) {
        var dataset = data.datasets[tooltipItem.datasetIndex];
        var meta = dataset._meta[Object.keys(dataset._meta)[0]];
        var total = meta.total;
        var currentValue = dataset.data[tooltipItem.index];
        var percentage = parseFloat((currentValue/total*100).toFixed(1));
        return `${currentValue} (${percentage}) %`;
      },
      title: function(tooltipItem:any, data:any) {
        return `${data.labels[tooltipItem[0].index]}`;
      }
    }
  },
  plugins: {
    datalabels: {
        color: 'white',
    }
  }
}

export const optionBarChart = {
  legend:{
  display:false
},
title: {
  display: true,
  text: ''
},
scales : {
  yAxes: [{
    ticks: {
      beginAtZero: true,
      steps: 10,
      stepValue: 5,
      userCallback: function(label:any, index:any, labels:any) {
        if (Math.floor(label) === label) {
          return label;
        }
      }
    },
    gridLines : {
      color: 'rgba(170,170,170,1)',
              lineWidth: 0.2
    }
    }],
  xAxes : [ {
    barPercentage: 0.1,
    gridLines : {
      display : false
    }
  } ]
},
  tooltips: {
    callbacks: {
      label: function(tooltipItem:any, data:any) {
        var dataset = data.datasets[tooltipItem.datasetIndex];
        var currentValue = dataset.data[tooltipItem.index];
        return currentValue;
      },
      title: function(tooltipItem:any, data:any) {
        return `${data.labels[tooltipItem[0].index]}`;
      }
    }
  }
}


export const optionFeedback = {
  legend:{
      display:false
},
  title: {
      display: true,
      text: ''
  },
  scales : {
      yAxes: [{
          ticks: {
            beginAtZero: true,
            steps: 10,
            stepValue: 5,
            userCallback: function(label:any, index:any, labels:any) {
                if (Math.floor(label) === label) {
                    return label;
                }
            }
          },
          scaleLabel: {
              display: true,
              labelString: 'Performance (%)'
            },
            gridLines : {
              color: 'rgba(170,170,170,1)',
                      lineWidth: 0.2
            }
        }],
      xAxes : [ {
          barPercentage: 0.1,
          gridLines : {
              display : false
          },
          scaleLabel: {
              display: true,
              labelString: 'Classes'
            }
      } ]
  }
}
