import React from 'react';
import { connect } from 'react-redux';
import { Field, Formik, Form, ErrorMessage } from 'formik';
import { Button, FormGroup, MenuItem } from '@material-ui/core';
import { commonKeys } from '../../services/Constants'
import { addSchoolLogo, commonGet } from '../../store/school/Actions';
import BreadCrumb from '../../components/BreadCrumb'
import history from '../../History';
import { Link } from 'react-router-dom';
import SpinnerLoader from '../../components/spinner/SpinnerLoader'
import { RootState } from '../../store/Index'
import { SchoolLogoValidation } from './SchoolValidation'
import { SCHOOLMANAGE } from '../../services/Config';
import '../../customtext.css'
import { SchoolImageUpload } from './SchoolImageUpload';
import { ModelImageView } from '../../components/ModelImageView';

const initialSchoolValues: any = {
    school_logo: [],
}

export type OwnSchoolFormProps = {
    addSchoolLogo: (school: any) => any;
    commonGet: (URL: any, body: any) => any;
    loading: boolean;
    errorMessage: any;
    commonCategoryList: any
};
interface propsSchoolTyps {
    latitudeAdd: any,
    longitudeAdd: any,
    schoolImage: any,
    image?: any;
    showImageView?: any;
}
class UploadSchoolLogoAll extends React.Component<OwnSchoolFormProps, propsSchoolTyps> {
    formik: any;
    constructor(props: any) {
        super(props);
        this.state = {
            latitudeAdd: 13.067439,
            longitudeAdd: 80.237617,
            schoolImage: [],
            image: "",
            showImageView: false,
        }
    }
    componentDidMount(): void {
        window.scrollTo(0, 0);
    }
   
    setImagesState = (setImages: any) => {
        this.setState({ schoolImage: setImages });
    }
    showImage = (image: any) => {
        this.setState({ image: image, showImageView: true });
    }
    onClose = () => {
        this.setState({ showImageView: false });
    }
    
    componentDidUpdate() {
    
    }

    // Synchronous validation
    render() {
        const { loading } = this.props;
        const { schoolImage, showImageView, image } = this.state;
        const loadingTextCSS = { display: loading ? "block" : "none" };
        const getToken = localStorage.getItem('token');
    
        if (!getToken) {
            history.push("/");
        }
        return (
            <div>
                <ModelImageView
                onState={showImageView}
                onCancel={this.onClose}
                image={image}
                />
                <div className="page-wrapper">
                    <div className="page-content-wrapper">
                        <div className="page-content pt-3">
                            <BreadCrumb
                                titleName={['Upload School Logo']}
                                homeName={['Home']}
                                url={['dashboard']}
                                mainPageTitle={['Upload School Logo']}
                                baseName={['School']}
                                baseURL={['school']} />
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card-box">
                                        <div className="card-head">
                                            <header>Upload School Logo</header>
                                        </div>
                                        <div className="card-body">
                                            <Formik
                                                ref={node => this.formik = node}
                                                validationSchema={SchoolLogoValidation}
                                                initialValues={initialSchoolValues}

                                                onSubmit={(values, actions) => {
                                                    const initialValuesList: any = {
                                                        school_logo: values.school_logo.length > 0 ? values.school_logo[0].data_url : ""
                                                    }
                                                    this.props.addSchoolLogo(initialValuesList)
                                                }}>
                                                {({ values, errors, isSubmitting, isValidating, dirty, touched, handleBlur, setFieldValue }) => {
                                                    const isSchoolEmpty = (!values.school_logo);
                                                    return (
                                                        <Form >
                                                            <div>
                                                                <div className="">
                                                                    <div className="row">
                                                                        <div className="col-md-6 p-t-20">
                                                                            <FormGroup>
                                                                                <legend className="MuiFormLabel-root ml-2">
                                                                                    <span>School Logo</span><span className='formmandatorycolor'> *</span>
                                                                                </legend>
                                                                            </FormGroup>
                                                                            <div className="p-t-20">
                                                                                <SchoolImageUpload setImages={schoolImage} setImagesState={this.setImagesState} setfield={setFieldValue} clickImage={this.showImage} />
                                                                                {values.school_logo.map((logo:any, index:any) => (
                                                                                        <div key={index}>
                                                                                            <ErrorMessage
                                                                                                name={`school_logo[${index}].data_url`}
                                                                                                component="div"
                                                                                                className="errorcolor"
                                                                                            />
                                                                                        </div>
                                                                                    ))}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className="text-right mb-3 mr-2 mt-4">
                                                                        <Button className="btn btn-pink mr-1 ml-1 w-15 mt-1"
                                                                            disabled={isSchoolEmpty || isValidating
                                                                                || !dirty ||
                                                                                !!(errors.school_logo && touched.school_logo)} type="submit">Submit</Button>
                                                                        <Link to="/school"><Button className="btn btn-default mr-1 ml-1 w-15">Cancel</Button>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Form>
                                                    )
                                                }
                                                }
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={loadingTextCSS}><SpinnerLoader /></div>
            </div>
        );
    }
}

const mapStateToProps = ({ schools }: RootState) => {
    return {
        loading: schools.loading,
        errorMessage: schools.errors,
        commonCategoryList: schools.schoolCategoryList
    };
};

export default connect(
    mapStateToProps, { addSchoolLogo, commonGet }
)(UploadSchoolLogoAll);
