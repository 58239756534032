import Modal from 'react-bootstrap/Modal'
export const PsychometricOtherTeacherList = (props: any) => {
  return (
    <div>
      <Modal show={props.onState}>
        <Modal.Header className="pb-0 pt-0">
          <Modal.Title>View Other Teachers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <table className="table table-striped custom-table table-hover">
              <thead>
                <tr>
                  <th>Other Teachers</th>
                </tr>
              </thead>
              <tbody>
                {props.otherTeacherList.map((item: any) => (
                  <tr>
                    <td className='teacher-label' title={item.user_name}>{item.user_name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        </Modal.Body>
        <Modal.Footer className="modelboxbutton">
          <button className="btn btn-default mr-1 ml-1 w-15" onClick={props.onCancel}>Close</button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
