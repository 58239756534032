import React, { Component } from "react";
import { connect } from "react-redux";
import CommonLoader from '../../components/CommonLoader';
import { Bar } from 'react-chartjs-2';

export type OwnReportProps = {
    subjectPerformanceReportData?: any;
    reportType?: any;
};

interface StateTypes {

}
export class SubjectPerformanceViewReport extends Component<OwnReportProps, StateTypes>{
    formik: any
    constructor(props: any) {
        super(props);
        this.state = {
          
        }
    }
    componentDidMount(): void {
        window.scrollTo(0, 0);
    }
    render() {
        const { subjectPerformanceReportData, reportType } = this.props;
        let labelsName: any;
        let backgroundColor: any;
        let dataCount: any;
        let present: any;
        let absent: any; 
     

        if(subjectPerformanceReportData !== undefined && subjectPerformanceReportData !== null){
			if(subjectPerformanceReportData){
				labelsName = [];
				backgroundColor = [];
				dataCount = [];
                present = [];
                absent = [];
				subjectPerformanceReportData.forEach((items:any)=>{
					labelsName.push(items.subject_name);
              
				})

     dataCount = [
        // {
        //     label: 'Total No of Questions',
        //   data: subjectPerformanceReportData.map((item:any) => item.no_of_questions),
        //   color_code: '#369c38',
        //   backgroundColor: '#369c38',
        // },
        {
            label: 'Total No of Attendees',
            data: subjectPerformanceReportData.map((item:any) => item.no_of_attendees),
          color_code: '#ea502e',
          backgroundColor: '#ea502e',
        },
        {
            label: 'No of Correct',
            data: subjectPerformanceReportData.map((item:any) => item.no_of_correct),
          color_code: '#40589E',
          backgroundColor: '#40589E',
        },{
            label: 'No of Incorrect',
            data: subjectPerformanceReportData.map((item:any) => item.no_of_in_correct),
          color_code: '#CFCC0B',
          backgroundColor: '#CFCC0B',
        },
        {
            label: 'No of Missed',
            data: subjectPerformanceReportData.map((item:any) => item.no_of_not_attempted),
          color_code: '#57C2EC',
          backgroundColor: '#57C2EC',
        }
      ];



			}
		}

        return (
            <div className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-5"}>
                <div className={`card card-topline-red`}>
                    <div className="card-head">
                        <header>
                        Subject-wise  {reportType == 'class' ? 'Class' : 'Student'} Performance</header>
                    </div>
                    <div className="card-body no-padding height-9" >
                        {subjectPerformanceReportData && subjectPerformanceReportData !== undefined && subjectPerformanceReportData !== null ?
                            <div className="row">
                            <Bar
                                data={{
                                    labels:labelsName,
                                    datasets:dataCount
                                }}
                                height={100}
                                options={{
                                    
                                    plugins: {
                                       
                                        datalabels: {
                                          display: true,
                                          color: "black",
                                          align: "end",
                                          anchor: "end",
                                          padding:0,
                                          offset: 5,
                                          rotation: '-90',
                                          //align: '-50',
                                          font: { size: "14" },
                                          formatter: (val:any) => {
                                            return val != 0 ? val+'%' : '';
                                          },
                                        },
                                      },
                                      scales: {
                                        yAxes: [{
                                            ticks: {
                                                beginAtZero: true,
                                                steps: 10,
                                                stepValue: 5,
                                                userCallback: function (label: any, index: any, labels: any) {
                                                    if (Math.floor(label) === label) {
                                                        return label;
                                                    }
                                                }
                                            },
                                            gridLines: {
                                                color: 'rgba(226,226,226,1)',
                                                //lineWidth: 0.2
                                            },
                                            scaleLabel: {
                                                display: true,
                                                labelString: 'Performance (%)'
                                            }
                                        }],
                                        xAxes: [{
                                            //barPercentage: 0.1,
                                            gridLines: {
                                                display: false
                                            },
                                            scaleLabel: {
                                                display: true,
                                                labelString: 'Subjects'
                                            }
                                        }]
                                    }
                                  
                                }}
                                legend={{
                                    display:true,
                                    position:'top',
                                    labels:{
                                       // boxHeight:100,
                                    }
                                 }}
                                 plugins={[{
                                    beforeInit: function (chart:any) {
                                      // Get reference to the original fit function
                                      const originalFit = chart.legend.fit
                                  
                                      // Override the fit function
                                      chart.legend.fit = function fit() {
                                        // Bind scope in order to use `this` correctly inside it
                                        originalFit.bind(chart.legend)()
                                        this.height += 60 // Change the height
                                      }
                                    }
                                  }]}
                            />
                        </div>
                            : <CommonLoader />}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

export default connect(mapStateToProps, {

})(SubjectPerformanceViewReport)