import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Doughnut } from 'react-chartjs-2';
import { fetchQuestionAnswersReport, getStudentViewAnswers } from '../../store/question/Actions';
import history from '../../History';
import { getAttendanceStudentView } from '../../store/profile/Actions';
import {optionDoughnut} from '../../components/chartoption/ChartCommonFun';

interface PropsQuestionAnwser {
    getQuizzesId?: any;
    fetchQuestionAnswersReport: (postValue: any) => any;
    getAttendanceStudentView:(postData:any) => any;
    getStudentViewAnswers:(postValue:any) => any;
    getQuestionAnswer?: any;
    getQuestionReport?:any;
}
interface PropsTypes {
    show: any
}
export class QuestionViewList extends Component<PropsQuestionAnwser, PropsTypes> {
    constructor(props: any) {
        super(props);
        this.state = {
            show: false
        }
    }
    componentDidMount(): void {
        const { getQuizzesId } = this.props;
        window.scrollTo(0, 0);
        if (getQuizzesId) {
            const postValue: any = {
                quiz_id: getQuizzesId
            }
            this.props.fetchQuestionAnswersReport(postValue);
        }
       
    }
    public showQuestionView(getValue:any) {       
        this.getSinglePageView(getValue);
    }

    getSinglePageView(getId:any){
        const { getQuizzesId } = this.props;
        if(getQuizzesId){
            const postValue: any = {
                quiz_id: getQuizzesId,
                question_id: getId
            }
            this.props.getStudentViewAnswers(postValue)
            history.push('/view_responses');
        }
    }

    render() {
        const { getQuestionAnswer } = this.props;
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        {getQuestionAnswer ?
                            getQuestionAnswer.map((items: any, index: any) => (
                                <div className="card card-topline-red">
                                    <div className="row">
                                        <div className="col-md-10 col-sm-10 col-xs-10 col-10">
                                            <p className="pl-3 pt-3">{index + 1}. {items.question}</p>
                                            <div className="col-md-12 pr-2">
                                                {/* need to change the key */}
                                                <button className="btn btn-xs btn-circle btn-pink cursorPoint break-word text-wrap text-break">{items.question_topic}</button>
                                            </div>
                                            <hr className="m-0 mt-2" />
                                           

                                        </div>
                                        {items?.chart_data ? <>
                                        <div className="col-md-2 col-sm-2 col-xs-2 col-2" >
                                            <button className="btn btn-info mt-3 pull-right mr-3" 
                                            onClick={() => this.showQuestionView(items.question_id)}>View Responses</button>
                                        </div> </> : <>
                                        <div className="col-md-2 col-sm-2 col-xs-2 col-2" >
                                            <button className="btn btn-pink mt-3 pull-right mr-3" 
                                            >Quizz not taken</button>
                                        </div>
                                        </> }
                                    </div>

                                    <div className="row p-3">
                                        <div className="col-md-7">
                                            {items ?
                                                items.options.map((optionList: any) => (
                                                    <div className="row">
                                                        <div className="col-md-10 col-sm-10 col-xs-10 col-8 mb-3">
                                                            <p>
                                                                <strong style={{color:optionList.color_code}}>{optionList.option}. </strong> 
                                                                <span>{optionList.value}</span></p>
                                                        </div>
                                                        <div className="col-md-2 col-sm-2 col-xs-2 col-4 mb-3">
                                                            <div className="btn btn-xs btn-circle countBtn" 
                                                            style={{background:optionList.color_code, color:'#ffffff'}}>{optionList.count}</div>
                                                        </div>
                                                    </div>
                                                ))
                                                : null}
                                        </div>
                                        <div className="col-md-5 col-12">
                                            <div className="row"> 
                                            <div className="col-lg-4 col-md-12 col-sm-4 col-12 mt-4">
                                            {items?.chart_data?.count ? (
                                               <div className="row mb-3">
                                               <div className="col-md-12 col-12 text-right">
                                               <label className="labelChart" htmlFor="">
                                                   <span className="ml-2 mr-2">Correct </span>
                                                   <i className="correctBG icount" >{items.chart_data.count[0]}</i>
                                                </label>
                                               </div>
                                               <div className="col-md-12 col-12 text-right">
                                               <label className="labelChart" htmlFor="">
                                                   <span className="ml-2 mr-2">Incorrect </span>
                                                   <i className="incorrectBG icount">{items.chart_data.count[1]}</i></label>
                                               </div>
                                               <div className="col-md-12 col-12 text-right">
                                               <label className="labelChart" htmlFor="">
                                                   <span className="ml-2 mr-2">Not Attempted </span>
                                                   <i className="notansBG icount">{items.chart_data.count[2]}</i> </label>
                                               </div>
                                       </div>
                                               ) : (
                                                <div className="row mb-3">
                                                <div className="col-md-12 col-12 text-right">
                                                <label className="labelChart" htmlFor="">
                                                    <span className="ml-2 mr-2">Correct </span>
                                                    <i className="correctBG icount" >0</i></label>
                                                </div>
                                                <div className="col-md-12 col-12 text-right">
                                                <label className="labelChart" htmlFor="">
                                                    <span className="ml-2 mr-2">Incorrect </span>
                                                    <i className="incorrectBG icount">0</i></label>
                                                </div>
                                                <div className="col-md-12 col-12 text-right">
                                                <label className="labelChart" htmlFor="">
                                                    <span className="ml-2 mr-2">Not Attempted </span>
                                                    <i className="notansBG icount">0</i></label>
                                                </div>
                                        </div>
                                               )}
                                            </div>
                                            <div className="col-lg-8 col-md-12 col-sm-8 col-12">
                                            {items?.chart_data && items.chart_data.count.some((count:any) => count > 0) ? (
                                                <Doughnut
                                                    data={{
                                                        labels: items.chart_data.name,
                                                        datasets: [{
                                                            data: items.chart_data.count,
                                                            backgroundColor: items.chart_data.color_code
                                                        }]
                                                    }}
                                                    height={130}
                                                    options={optionDoughnut}
                                                />
                                               ) : null}
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                            : null}

                    </div>

                </div>
            </div>
        )
    }
}
export const option = {
    legend: {
        display: false
      },
    tooltips: {
      callbacks: {
        label: function(tooltipItem:any, data:any) {
          var dataset = data.datasets[tooltipItem.datasetIndex];
          var meta = dataset._meta[Object.keys(dataset._meta)[0]];
          var total = meta.total;
          var currentValue = dataset.data[tooltipItem.index];
          var percentage = parseFloat((currentValue/total*100).toFixed(1));
          return currentValue + ' (' + percentage + '%)';
        },
        title: function(tooltipItem:any, data:any) {
          return data.labels[tooltipItem[0].index];
        }
      }
    }
  }

const mapStateToProps = (state: any) => {
    return {
        getQuestionAnswer: state.questionset.QuestionAnswersReport,
        getQuestionReport: state.questionset.questionReportView
    }
}

export default connect(mapStateToProps, { fetchQuestionAnswersReport, getAttendanceStudentView, getStudentViewAnswers })(QuestionViewList)
