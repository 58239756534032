import { ThunkAction } from "redux-thunk";
import { RootActions, RootState } from "../Index";
import { Dispatch } from "redux";
import { AxiosResponse } from "axios";
import { baseAPI } from "../../Service";
import toaster from "toasted-notes";
import { notificationMsg } from "../../services/Constants";
import { ReportTypes } from "./Types";
import { Reports } from "../../services/Config"; 

export type ThunkResult<R> = ThunkAction<R, RootState, undefined, RootActions>;

interface teacherDropdown{
    type: ReportTypes.TEACHER_DROPDOWN
}
interface teacherDropdownSuccess{
    type: ReportTypes.TEACHER_DROPDOWN_SUCCESS,
    payload: any,
}
interface teacherDropdownFail{
    type: ReportTypes.TEACHER_DROPDOWN_FAIL
}

export const GetTeacherDropdown = (data: any): ThunkResult<void> => async dispatch => {
    handleTeacherDropdown(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.get(Reports.TeacherDropdown+`?academic_year=${data.academic_year}&grade=${data.grade_id}&class_id=${data.class_id}`, {
                headers: {
                    "Authorization" : localStorage.getItem('token')
                }
            });
            if (response.data.status === true) {
                handleGetTeacherDropdownSuccess(dispatch, response.data);
            }else{
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleGetTeacherDropdownFail(dispatch);
            }
    } catch (e) {
        handleGetTeacherDropdownFail(dispatch);
    }
};

export const handleTeacherDropdown = (dispatch: Dispatch<teacherDropdown>) => {
    dispatch({ type: ReportTypes.TEACHER_DROPDOWN });
};

export const handleGetTeacherDropdownSuccess = (
    dispatch: Dispatch<teacherDropdownSuccess>,
    response: any
) => {
    dispatch({
        type: ReportTypes.TEACHER_DROPDOWN_SUCCESS,
        payload: response,
    });

};

export const handleGetTeacherDropdownFail = (dispatch: Dispatch<teacherDropdownFail>) => {
    dispatch({
        type: ReportTypes.TEACHER_DROPDOWN_FAIL
    });
};

interface getsubjectDropdown{
    type:ReportTypes.SUBJECT_DROPDOWN
};
interface getsubjectDropdownSuccess{
    type:ReportTypes.SUBJECT_DROPDOWN_SUCCESS,
    payload:any
};
interface getsubjectDropdownFail{
    type:ReportTypes.SUBJECT_DROPDOWN_FAIL
};

export const GetSubjectDropdown = (data: any): ThunkResult<void> => async dispatch => {
    handleSubjectDropdown(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.get(Reports.SubjectDropdown+`?academic_year=${data.academic_year}&grade=${data.grade_id}&teacher_id=${data.teacher_id}&class_id=${data.class_id}`, {
                headers: {
                    "Authorization" : localStorage.getItem('token')
                }
            });
            if (response.data.status === true) {
                handleGetSubjectDropdownSuccess(dispatch, response.data);
            }else{
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleGetSubjectDropdownFail(dispatch);
            }
    } catch (e) {
        handleGetSubjectDropdownFail(dispatch);
    }
};

export const handleSubjectDropdown = (dispatch: Dispatch<getsubjectDropdown>) => {
    dispatch({ type: ReportTypes.SUBJECT_DROPDOWN });
};

export const handleGetSubjectDropdownSuccess = (
    dispatch: Dispatch<getsubjectDropdownSuccess>,
    response: any
) => {
    dispatch({
        type: ReportTypes.SUBJECT_DROPDOWN_SUCCESS,
        payload: response,
    });

};

export const handleGetSubjectDropdownFail = (dispatch: Dispatch<getsubjectDropdownFail>) => {
    dispatch({
        type: ReportTypes.SUBJECT_DROPDOWN_FAIL
    });
};

interface getSubjectAnalysisreport{
    type:ReportTypes.SUBJECT_ANALYSIS_REPORT
};
interface getSubjectAnalysisreportSuccess{
    type:ReportTypes.SUBJECT_ANALYSIS_REPORT_SUCCESS,
    payload:any
};
interface getSubjectAnalysisreportFail{
    type:ReportTypes.SUBJECT_ANALYSIS_REPORT_FAIL
};

export const GetSubjectAnalysisReport = (data: any): ThunkResult<void> => async dispatch => {
    handleSubjectAnalysisReport(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.get(Reports.SubjectAnalysisReport+`?academic_year=${data.academic_year}&grade=${data.grade_id}&class_id=${data.class_id}&card_id=${data.card_id}`, {
                headers: {
                    "Authorization" : localStorage.getItem('token')
                }
            });
            if (response.data.status === true) {
                handleSubjectAnalysisReportSuccess(dispatch, response.data);
            }else{
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleSubjectAnalysisReportFail(dispatch);
            }
    } catch (e) {
        handleSubjectAnalysisReportFail(dispatch);
    }
};

export const handleSubjectAnalysisReport = (dispatch: Dispatch<getSubjectAnalysisreport>) => {
    dispatch({ type: ReportTypes.SUBJECT_ANALYSIS_REPORT });
};

export const handleSubjectAnalysisReportSuccess = (
    dispatch: Dispatch<getSubjectAnalysisreportSuccess>,
    response: any
) => {
    dispatch({
        type: ReportTypes.SUBJECT_ANALYSIS_REPORT_SUCCESS,
        payload: response,
    });

};

export const handleSubjectAnalysisReportFail = (dispatch: Dispatch<getSubjectAnalysisreportFail>) => {
    dispatch({
        type: ReportTypes.SUBJECT_ANALYSIS_REPORT_FAIL
    });
};

interface getTeacherEngagementReport{
    type:ReportTypes.TEACHER_ENGAGEMENT_REPORT
};
interface getTeacherEngagementReportSuccess{
    type:ReportTypes.TEACHER_ENGAGEMENT_REPORT_SUCCESS,
    payload:any
};
interface getTeacherEngagementReportFail{
    type:ReportTypes.TEACHER_ENGAGEMENT_REPORT_FAIL
};

export const GetTeacherEngagementReport = (data: any): ThunkResult<void> => async dispatch => {
    handleTeacherEngagementReport(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.get(Reports.TeacherEngagementReport+`?academic_year=${data.academic_year}&page_no=${data.page_no}`, {
                headers: {
                    "Authorization" : localStorage.getItem('token')
                }
            });
            if (response.data.status === true) {
                handleTeacherEngagementReportSuccess(dispatch, response.data);
            }else{
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleTeacherEngagementReportFail(dispatch);
            }
    } catch (e) {
        handleTeacherEngagementReportFail(dispatch);
    }
};

export const handleTeacherEngagementReport = (dispatch: Dispatch<getTeacherEngagementReport>) => {
    dispatch({ type: ReportTypes.TEACHER_ENGAGEMENT_REPORT });
};

export const handleTeacherEngagementReportSuccess = (
    dispatch: Dispatch<getTeacherEngagementReportSuccess>,
    response: any
) => {
    dispatch({
        type: ReportTypes.TEACHER_ENGAGEMENT_REPORT_SUCCESS,
        payload: response,
        records_Teacher_Engagement: response.data.records,
        per_page_Teacher_Engagement: response.data.per_page,
        page_Teacher_Engagement: response.data.page,
        total_Teacher_Engagement: response.data.total
    });

};

export const handleTeacherEngagementReportFail = (dispatch: Dispatch<getTeacherEngagementReportFail>) => {
    dispatch({
        type: ReportTypes.TEACHER_ENGAGEMENT_REPORT_FAIL
    });
};

interface getTeacherPerformanceReport{
    type:ReportTypes.TEACHER_PERFORMANCE_REPORT
};
interface getTeacherPerformanceReportSuccess{
    type:ReportTypes.TEACHER_PERFORMANCE_REPORT_SUCCESS,
    payload:any
};
interface getTeacherPerformanceReportFail{
    type:ReportTypes.TEACHER_PERFORMANCE_REPORT_FAIL
};

export const GetTeacherPerformanceReport = (data: any): ThunkResult<void> => async dispatch => {
    handleTeacherPerformanceReport(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.get(Reports.TeacherPerformanceReport+`?academic_year=${data.academic_year}&page_no=${data.page_no}&class_id=${data.class_id}`, {
                headers: {
                    "Authorization" : localStorage.getItem('token')
                }
            });
            if (response.data.status === true) {
                handleTeacherPerformanceReportSuccess(dispatch, response.data);
            }else{
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleTeacherPerformanceReportFail(dispatch);
            }
    } catch (e) {
        handleTeacherPerformanceReportFail(dispatch);
    }
};

export const handleTeacherPerformanceReport = (dispatch: Dispatch<getTeacherPerformanceReport>) => {
    dispatch({ type: ReportTypes.TEACHER_PERFORMANCE_REPORT });
};

export const handleTeacherPerformanceReportSuccess = (
    dispatch: Dispatch<getTeacherPerformanceReportSuccess>,
    response: any
) => {
    dispatch({
        type: ReportTypes.TEACHER_PERFORMANCE_REPORT_SUCCESS,
        payload: response,
        records_Teacher_Performance: response.data.records,
        per_page_Teacher_Performance: response.data.per_page,
        page_Teacher_Performance: response.data.page,
        total_Teacher_Performance: response.data.total
    });

};

export const handleTeacherPerformanceReportFail = (dispatch: Dispatch<getTeacherPerformanceReportFail>) => {
    dispatch({
        type: ReportTypes.TEACHER_PERFORMANCE_REPORT_FAIL
    });
};

interface getTeacherAttendanceReport{
    type:ReportTypes.TEACHER_ATTENDANCE_REPORT
};
interface getTeacherAttendanceReportSuccess{
    type:ReportTypes.TEACHER_ATTENDANCE_REPORT_SUCCESS,
    payload:any
};
interface getTeacherAttendanceReportFail{
    type:ReportTypes.TEACHER_ATTENDANCE_REPORT_FAIL
};

export const GetTeacherAttendanceReport = (data: any): ThunkResult<void> => async dispatch => {
    handleTeacherAttendanceReport(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.get(Reports.TeacherAttendanceReport+`?academic_year=${data.academic_year}&grade=${data.grade_id}&class_id=${data.class_id}`, {
                headers: {
                    "Authorization" : localStorage.getItem('token')
                }
            });
            console.log(response.data.status)
            if (response.data.status === true) {
                handleTeacherAttendanceReportSuccess(dispatch, response.data);
            }else{
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleTeacherAttendanceReportFail(dispatch);
            }
    } catch (e) {
        handleTeacherAttendanceReportFail(dispatch);
    }
};

export const handleTeacherAttendanceReport = (dispatch: Dispatch<getTeacherAttendanceReport>) => {
    dispatch({ type: ReportTypes.TEACHER_ATTENDANCE_REPORT });
};

export const handleTeacherAttendanceReportSuccess = (
    dispatch: Dispatch<getTeacherAttendanceReportSuccess>,
    response: any
) => {
    dispatch({
        type: ReportTypes.TEACHER_ATTENDANCE_REPORT_SUCCESS,
        payload: response,
    });

};

export const handleTeacherAttendanceReportFail = (dispatch: Dispatch<getTeacherAttendanceReportFail>) => {
    dispatch({
        type: ReportTypes.TEACHER_ATTENDANCE_REPORT_FAIL
    });
};

interface getClassAttendanceReport{
    type:ReportTypes.CLASS_ATTENDANCE_REPORT
};
interface getClassAttendanceReportSuccess{
    type:ReportTypes.CLASS_ATTENDANCE_REPORT_SUCCESS,
    payload:any
};
interface getClassAttendanceReportFail{
    type:ReportTypes.CLASS_ATTENDANCE_REPORT_FAIL
};

export const GetClassAttendanceReport = (data: any): ThunkResult<void> => async dispatch => {
    handleClassAttendanceReport(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.get(Reports.ClassAttendanceReport+`?academic_year=${data.academic_year}&grade=${data.grade_id}&class_id=${data.class_id}&card_id=${data.card_id}`, {
                headers: {
                    "Authorization" : localStorage.getItem('token')
                }
            });
            console.log(response.data.status)
            if (response.data.status === true) {
                handleClassAttendanceReportSuccess(dispatch, response.data);
            }else{
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleClassAttendanceReportFail(dispatch);
            }
    } catch (e) {
        handleClassAttendanceReportFail(dispatch);
    }
};

export const handleClassAttendanceReport = (dispatch: Dispatch<getClassAttendanceReport>) => {
    dispatch({ type: ReportTypes.CLASS_ATTENDANCE_REPORT });
};

export const handleClassAttendanceReportSuccess = (
    dispatch: Dispatch<getClassAttendanceReportSuccess>,
    response: any
) => {
    dispatch({
        type: ReportTypes.CLASS_ATTENDANCE_REPORT_SUCCESS,
        payload: response,
    });

};

export const handleClassAttendanceReportFail = (dispatch: Dispatch<getClassAttendanceReportFail>) => {
    dispatch({
        type: ReportTypes.CLASS_ATTENDANCE_REPORT_FAIL
    });
};

interface getSubjectPerformanceReport{
    type:ReportTypes.SUBJECT_PERFORMANCE_REPORT
};
interface getSubjectPerformanceReportSuccess{
    type:ReportTypes.SUBJECT_PERFORMANCE_REPORT_SUCCESS,
    payload:any
};
interface getSubjectPerformanceReportFail{
    type:ReportTypes.SUBJECT_PERFORMANCE_REPORT_FAIL
};

export const GetSubjectPerformanceReport = (data: any): ThunkResult<void> => async dispatch => {
    handleSubjectPerformanceReport(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.get(Reports.SubjectPerformanceReport+`?academic_year=${data.academic_year}&grade=${data.grade_id}&class_id=${data.class_id}&card_id=${data.card_id}`, {
                headers: {
                    "Authorization" : localStorage.getItem('token')
                }
            });
            if (response.data.status === true) {
                handleSubjectPerformanceReportSuccess(dispatch, response.data);
            }else{
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleSubjectPerformanceReportFail(dispatch);
            }
    } catch (e) {
        handleSubjectPerformanceReportFail(dispatch);
    }
};

export const handleSubjectPerformanceReport = (dispatch: Dispatch<getSubjectPerformanceReport>) => {
    dispatch({ type: ReportTypes.SUBJECT_PERFORMANCE_REPORT });
};

export const handleSubjectPerformanceReportSuccess = (
    dispatch: Dispatch<getSubjectPerformanceReportSuccess>,
    response: any
) => {
    dispatch({
        type: ReportTypes.SUBJECT_PERFORMANCE_REPORT_SUCCESS,
        payload: response,
    });

};

export const handleSubjectPerformanceReportFail = (dispatch: Dispatch<getSubjectPerformanceReportFail>) => {
    dispatch({
        type: ReportTypes.SUBJECT_PERFORMANCE_REPORT_FAIL
    });
};

interface getSubjectAttendivenessReport{
    type:ReportTypes.SUBJECT_ATTENDIVENESS_REPORT
};
interface getSubjectAttendivenessReportSuccess{
    type:ReportTypes.SUBJECT_ATTENDIVENESS_REPORT_SUCCESS,
    payload:any
};
interface getSubjectAttendivenessReportFail{
    type:ReportTypes.SUBJECT_ATTENDIVENESS_REPORT_FAIL
};

export const GetSubjectAttendivenessReport = (data: any): ThunkResult<void> => async dispatch => {
    handleSubjectAttendivenessReport(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.get(Reports.SubjectAttendivenessReport+`?academic_year=${data.academic_year}&grade=${data.grade_id}`, {
                headers: {
                    "Authorization" : localStorage.getItem('token')
                }
            });
            if (response.data.status === true) {
                handleSubjectAttendivenessReportSuccess(dispatch, response.data);
            }else{
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleSubjectAttendivenessReportFail(dispatch);
            }
    } catch (e) {
        handleSubjectAttendivenessReportFail(dispatch);
    }
};

export const handleSubjectAttendivenessReport = (dispatch: Dispatch<getSubjectAttendivenessReport>) => {
    dispatch({ type: ReportTypes.SUBJECT_ATTENDIVENESS_REPORT });
};

export const handleSubjectAttendivenessReportSuccess = (
    dispatch: Dispatch<getSubjectAttendivenessReportSuccess>,
    response: any
) => {
    dispatch({
        type: ReportTypes.SUBJECT_ATTENDIVENESS_REPORT_SUCCESS,
        payload: response,
    });

};

export const handleSubjectAttendivenessReportFail = (dispatch: Dispatch<getSubjectAttendivenessReportFail>) => {
    dispatch({
        type: ReportTypes.SUBJECT_ATTENDIVENESS_REPORT_FAIL
    });
};

interface getTeacherOverallReport{
    type:ReportTypes.TEACHER_OVERALL_REPORT
};
interface getTeacherOverallReportSuccess{
    type:ReportTypes.TEACHER_OVERALL_REPORT_SUCCESS,
    payload:any
};
interface getTeacherOverallReportFail{
    type:ReportTypes.TEACHER_OVERALL_REPORT_FAIL
};

export const GetTeacherOverallReport = (data: any): ThunkResult<void> => async dispatch => {
    handleTeacherOverallReport(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.get(Reports.TeacherOverallReport+`?academic_year=${data.academic_year}&grade=${data.grade_id}&teacher_id=${data.teacher_id}&subject_id=${data.subject_id}`, {
                headers: {
                    "Authorization" : localStorage.getItem('token')
                }
            });
            if (response.data.status === true) {
                handleTeacherOverallReportSuccess(dispatch, response.data);
            }else{
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleTeacherOverallReportFail(dispatch);
            }
    } catch (e) {
        handleTeacherOverallReportFail(dispatch);
    }
};

export const handleTeacherOverallReport = (dispatch: Dispatch<getTeacherOverallReport>) => {
    dispatch({ type: ReportTypes.TEACHER_OVERALL_REPORT });
};

export const handleTeacherOverallReportSuccess = (
    dispatch: Dispatch<getTeacherOverallReportSuccess>,
    response: any
) => {
    dispatch({
        type: ReportTypes.TEACHER_OVERALL_REPORT_SUCCESS,
        payload: response,
    });

};

export const handleTeacherOverallReportFail = (dispatch: Dispatch<getTeacherOverallReportFail>) => {
    dispatch({
        type: ReportTypes.TEACHER_OVERALL_REPORT_FAIL
    });
};

interface getSubjectExamReport {
    type: ReportTypes.SUBJECT_EXAM_REPORT
}
interface getSubjectExamReportSuccess {
    type: ReportTypes.SUBJECT_EXAM_REPORT_SUCCESS,
    payload:any
}
interface getSubjectExamReportFail {
    type: ReportTypes.SUBJECT_EXAM_REPORT_FAIL
}
export const GetSubjectExamReport = (data: any): ThunkResult<void> => async dispatch => {
    handleGetSubjectExamReport(dispatch);
    try{
        const response: AxiosResponse<any> = await baseAPI.get(Reports.GetSubjectExamReport+`?academic_year=${data.academic_year}&grade=${data.grade_id}&class_id=${data.class_id}`, {
                headers: {
                    "Authorization" : localStorage.getItem('token')
                }
            });
            if (response.data.status === true) {
                handleGetSubjectExamReportSuccess(dispatch, response.data);
            }else{
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                });
                handleGetSubjectExamReportFail(dispatch);
            }
    } catch (e) {
        handleGetSubjectExamReportFail(dispatch);
    }
};

export const handleGetSubjectExamReport = (dispatch: Dispatch<getSubjectExamReport>) => {
    dispatch({ type: ReportTypes.SUBJECT_EXAM_REPORT });
};

export const handleGetSubjectExamReportSuccess = (
    dispatch: Dispatch<getSubjectExamReportSuccess>,
    response: any
) => {
    dispatch({
        type: ReportTypes.SUBJECT_EXAM_REPORT_SUCCESS,
        payload: response,
    });

};

export const handleGetSubjectExamReportFail = (dispatch: Dispatch<getSubjectExamReportFail>) => {
    dispatch({
        type: ReportTypes.SUBJECT_EXAM_REPORT_FAIL
    });
};
export type ReportAction = | teacherDropdownSuccess;
