import React, { Component } from "react";
import { connect } from "react-redux";
import CommonLoader from '../../components/CommonLoader';
import { Bar } from 'react-chartjs-2';

export type OwnReportProps = {
    subjectAnalysisReport?: any;
    reportType?: string;
};

interface StateTypes {

}
export class SubjectAnalysisViewReport extends Component<OwnReportProps, StateTypes>{
    formik: any
    constructor(props: any) {
        super(props);
        this.state = {
          
        }
    }
    componentDidMount(): void {
        window.scrollTo(0, 0);
    }
    render() {
        const { subjectAnalysisReport } = this.props;

        let labelsName:any;
		let backgroundColor:any;
		let dataCount:any;
        
        if (subjectAnalysisReport !== undefined && subjectAnalysisReport !== null) {
            if (subjectAnalysisReport) {

                if (subjectAnalysisReport.graph_data) {
                    labelsName = [];
				backgroundColor = [];
				dataCount = [];
				subjectAnalysisReport.graph_data.forEach((items:any)=>{
					// labelsName.push(items.class_name);
					// backgroundColor.push(items.color_code)
					// dataCount.push(items.performance)

                    items.backgroundColor = items.color_code;
						items.label = items.class_name;
						items.data = items.performance;

				})
                }
            }
        }

        


        return (
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mt-5">
                <div className={`card card-topline-red`}>
                    <div className="card-head">
                        <header>Subject Analysis</header>
                    </div>
                    <div className="card-body no-padding height-9" >
                        {subjectAnalysisReport && subjectAnalysisReport !== undefined && subjectAnalysisReport !== null && subjectAnalysisReport.subject_name_list.length > 0 ?
                            <div className="row">
                                <Bar
                                    data={{
                                        labels: subjectAnalysisReport.subject_name_list,
                                        datasets: subjectAnalysisReport.graph_data
                                    }}
                                    height={150}
                                    options={{
                                        legend: {
                                            display: true
                                        },
                                        title: {
                                            display: true,
                                            text: ''
                                        },
                                        plugins: {
                                            datalabels: {
                                              display: true,
                                              color: "black",
                                              align: "end",
                                              anchor: "end",
                                              font: { size: "14" },
                                              formatter: (val:any) => {
                                                return val+'%';
                                              },
                                            }
                                          },
                                        scales: {
                                            yAxes: [{
                                                ticks: {
                                                    beginAtZero: true,
                                                    steps: 10,
                                                    stepValue: 5,
                                                    userCallback: function (label: any, index: any, labels: any) {
                                                        if (Math.floor(label) === label) {
                                                            return label;
                                                        }
                                                    }
                                                },
                                                gridLines: {
                                                    color: 'rgba(226,226,226,1)',
                                                },
                                                scaleLabel: {
                                                    display: true,
                                                    labelString: 'Performance (%)'
                                                }
                                            }],
                                            xAxes: [{
                                                gridLines: {
                                                    display: false
                                                },
                                                scaleLabel: {
                                                    display: true,
                                                    labelString: 'Subjects'
                                                }
                                            }]
                                        }
                                    }}
                                    legend={{
                                        display:false,
                                        position:'top',
                                        labels:{
                                            boxHeight:100,
                                        }
                                     }}
                                     plugins={[{
                                        beforeInit: function (chart:any) {
                                          // Get reference to the original fit function
                                          const originalFit = chart.legend.fit
                                      
                                          // Override the fit function
                                          chart.legend.fit = function fit() {
                                            // Bind scope in order to use `this` correctly inside it
                                            originalFit.bind(chart.legend)()
                                            this.height += 20 // Change the height
                                          }
                                        }
                                      }]}
                                />
                            </div>
                            : <CommonLoader />}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

export default connect(mapStateToProps, {

})(SubjectAnalysisViewReport)