import React, { Component } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/BreadCrumb";
import { Link } from 'react-router-dom';
import { QuestionBank } from '../../router/Roles';
import { Field, Formik, Form, FieldArray, ErrorMessage } from "formik";
import { Button, MenuItem, FormGroup } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { get } from "lodash";
import {
    fetchBluePrintGradeDropdownGet, fetchBluePrintSubjectDropdownGet,
    fetchBluePrintExamTypeDropdownGet, fetchBluePrintLessonDropdownGet,
    fetchBluePrintDifficultyLevelDropdownGet, fetchBluePrintQuestionTypeDropdownGet,
    addFeedbackQuestions
} from '../../store/QuestionBank/Actions';
import { getYearData, stopYearData } from '../../store/profile/Actions'
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import { feedBackAddschema } from "./QuestionBankValidation";
import QuestionImageSelect from "../../components/QuestionImageUpload";
import OptionImageSelect from "../../components/OptionImageUpload";
import AnswerImageSelect from "../../components/AnswerImageUpload";
import ReadPassageSubQuesImageUpload from "../../components/ReadPassageSubQuesImageUpload";
import { ModelImageView } from './ModelImageView';

export type Props = {
    loading: boolean;
    getChangeYearData?: any;
    stopYearDate?: any;
    bluePrintGradeDropdown?: any;
    bluePrintSubjectDropdown?: any;
    bluePrintExamTypeDropdown?: any;
    bluePrintLessonDropdown?: any;
    bluePrintDifficultyLevelDropdown?: any;
    bluePrintQuestionTypeDropdown?: any;
    stopYearData: () => any;
    fetchBluePrintGradeDropdownGet: (data: any) => any;
    fetchBluePrintSubjectDropdownGet: (data: any) => any;
    fetchBluePrintExamTypeDropdownGet: (data: any) => any;
    fetchBluePrintLessonDropdownGet: (data: any) => any;
    fetchBluePrintDifficultyLevelDropdownGet: () => any;
    fetchBluePrintQuestionTypeDropdownGet: () => any;
    addFeedbackQuestions: (data: any) => any;
}

interface StateTypes {
    academicYear?: any;
    grade?: any;
    gradeId?: any;
    subject?: any;
    examTypes?: any;
    total_marks?: any;
    question_type?: any;
    question_type_marks?: any;
    lessons?: any;
    difficulty_level?: any;
    options?: any;
    questionImage?: any;
    image?: any;
    showImageView?: any;
    subQuestionImage?: any;
    answerImage?: any;
}
const initialValues = {
    select_grade: "",
    select_subject: "",
    lessons: "",
    question_type_marks: "",
    question_type: "",
    question_type_name: "",
    difficulty_level: "",
    question: "",
    question_image: "",
    // question_option_A: "",
    // question_option_B: "",
    // question_option_C: "",
    // question_option_D: "",
    question_correct_answer: "",
    optionA: { text: '', image: "" },
    optionB: { text: '', image: "" },
    optionC: { text: '', image: "" },
    optionD: { text: '', image: "" },
    answer: "",
    answer_image: "",
    sub_question_image: "",
    sub_question_list: [
        {
            question: '',
            question_image: '',
            marks: 1
        }
    ],
};

export class FeedbackQuestionsAdd extends Component<Props, StateTypes> {
    formik: any;
    constructor(props: any) {
        super(props);
        this.state = {
            grade: [],
            gradeId: "",
            subject: [],
            examTypes: [],
            total_marks: 0,
            question_type: [],
            question_type_marks: [],
            lessons: [],
            difficulty_level: [],
            academicYear: null,
            questionImage: [],
            options: {
                A: { text: '', image: [] },
                B: { text: '', image: [] },
                C: { text: '', image: [] },
                D: { text: '', image: [] }
            },
            image: "",
            showImageView: false,
            subQuestionImage: []
        }
    }
    componentDidMount(): void {
        window.scrollTo(0, 0);
        this.getCurrectYear();
    }
    componentWillUpdate(nextProps: any, nextState: any) {
        const { stopYearDate } = this.props;
        if (stopYearDate === true) {
            this.getCurrectYear(this.props.getChangeYearData);
        }
    }
    componentDidUpdate() {
        const { stopYearDate } = this.props;
        if (stopYearDate === true) {
            this.props.stopYearData();
        }
    }

    getCurrectYear(getYear?: any) {
        if (getYear && getYear !== undefined && getYear !== null) {
            this.getBluePrintDetails(getYear);
            this.setState({ academicYear: getYear });
        } else {
            this.getBluePrintDetails(this.props.getChangeYearData);
            this.setState({ academicYear: this.props.getChangeYearData });
        }
    }

    getBluePrintDetails(getYear?: any) {
        const getData = {
            academic_year: getYear,
        }
        this.props.fetchBluePrintGradeDropdownGet(getData).then((res: any) => {
            const { bluePrintGradeDropdown } = this.props;
            this.setState({ grade: bluePrintGradeDropdown });
        });
        this.props.fetchBluePrintExamTypeDropdownGet(getData).then((res: any) => {
            const { bluePrintExamTypeDropdown } = this.props;
            this.setState({ examTypes: bluePrintExamTypeDropdown });
        });
        this.props.fetchBluePrintDifficultyLevelDropdownGet().then((res: any) => {
            const { bluePrintDifficultyLevelDropdown } = this.props;
            this.setState({ difficulty_level: bluePrintDifficultyLevelDropdown });
        });
        this.props.fetchBluePrintQuestionTypeDropdownGet().then((res: any) => {
            const { bluePrintQuestionTypeDropdown } = this.props;
            this.setState({ question_type: bluePrintQuestionTypeDropdown });
        });
    }


    getMarkOptions = (typeId: any) => {
        const { question_type } = this.state;
        const questionType = question_type.find((type: any) => type.id === typeId);
        return questionType ? questionType.marks : [];
    };

    getQuestionTypeName = (typeId: any) => {
        const { question_type } = this.state;
        const questionType = question_type.find((type: any) => type.id === typeId);
        return questionType ? questionType.name : '';
    };

    getSubjectDropdown = (value: any) => {
        const { academicYear } = this.state;
        const getData = {
            academic_year: academicYear,
            internal_grade_id: value
        }
        this.props.fetchBluePrintSubjectDropdownGet(getData).then((res: any) => {
            const { bluePrintSubjectDropdown } = this.props;
            this.setState({ gradeId: value, subject: bluePrintSubjectDropdown });
        });
    }

    getLessonDropdown = (value: any) => {
        const { academicYear, gradeId } = this.state;
        const getData = {
            academic_year: academicYear,
            internal_grade_id: gradeId,
            internal_subject_id: value
        }
        this.props.fetchBluePrintLessonDropdownGet(getData).then((res: any) => {
            const { bluePrintLessonDropdown } = this.props;
            this.setState({ lessons: bluePrintLessonDropdown });
        });
    }

    handleImageChange = (event: any, option: any) => {
        const file = event.target.files[0];
        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
        // if (file && allowedTypes.includes(file.type) && file.size <= 2 * 1024 * 1024) {
        const reader = new FileReader();
        reader.onloadend = (e: any) => {
            this.setState(prevState => ({
                options: {
                    ...prevState.options,
                    [option]: { ...prevState.options[option], image: reader.result }
                }
            }));
        };
        reader.readAsDataURL(file);
        //}
    };
    handleQuestionImageChange = (event: any) => {
        const file = event.target.files[0];
        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
        //if (file && allowedTypes.includes(file.type) && file.size <= 2 * 1024 * 1024) {
        const reader = new FileReader();
        reader.onloadend = (e: any) => {
            this.setState(prevState => ({
                questionImage: [reader.result]
            }));

        };
        reader.readAsDataURL(file);
        //}
    };

    setImagesState = (setImages: any) => {
        this.setState({ questionImage: setImages });
    };

    setSubQuesImage = (setImages: any,index:any) => {
        this.setState(prevState => ({
            subQuestionImage: {
                ...prevState.subQuestionImage,
                [index]: { ...prevState.subQuestionImage[index], question_image: setImages }
            }
        }));
    };

    setMatchQImage = (setImages:any) => {
        this.setState({ answerImage: setImages})
    };

    setOptionImagesState = (setImages: any, option: any) => {
        this.setState(prevState => ({
            options: {
                ...prevState.options,
                [option]: { ...prevState.options[option], image: setImages }
            }
        }));
    };

    showImage = (image: any) => {
        this.setState({ image: image, showImageView: true });
    }

    onClose = () => {
        this.setState({ showImageView: false });
    }

    render() {
        const { grade, subject, examTypes, total_marks, question_type, lessons, difficulty_level, options, academicYear, questionImage, showImageView, image, subQuestionImage, answerImage} = this.state;
        const { loading } = this.props;
        const loadingQuestionFull = { display: loading ? "block" : "none" };
        return (
            <div className="page-wrapper">
                <ModelImageView
                    onState={showImageView}
                    onCancel={this.onClose}
                    image={image}
                />
                <div className="page-content-wrapper">
                    <div className="page-content pt-3">
                        <BreadCrumb
                            titleName={['Add Feedback Question']}
                            homeName={['Home']}
                            url={['dashboard']}
                            mainPageTitle={['Add Feedback Question']} />

                        <div className="row">
                            <div className="col-md-12">
                                <Formik
                                    ref={node => this.formik = node}
                                    initialValues={initialValues}
                                    validationSchema={feedBackAddschema}

                                    onSubmit={(values, { setSubmitting }) => {
                                        console.log(values)
                                        const feedbackQuestionsValues: any = {
                                            academic_year: academicYear,
                                            internal_grade_id: parseInt(values.select_grade),
                                            internal_subject_id: parseInt(values.select_subject),
                                            lesson_id: values.lessons,
                                            question_type_id: values.question_type,
                                            marks: values.question_type_marks,
                                            question: values.question,
                                            question_image: values.question_image,
                                            difficulty_level_id: values.difficulty_level,
                                            answer: values.answer,
                                            answer_image: values.answer_image,
                                            sub_question_list: values.sub_question_list.map((item:any) => ({
                                                "question": item.question,
                                                "question_image": item.question_image,
                                                "marks": 1
                                            })),
                                            feedback_options: this.getQuestionTypeName(values.question_type) == 'MCQ' ? [
                                                {
                                                    option: "A",
                                                    content: values.optionA.text,
                                                    answer_image: values.optionA.image,
                                                    is_correct_answer: values.question_correct_answer == 'A' ? true : false,
                                                },
                                                {
                                                    option: "B",
                                                    content: values.optionB.text,
                                                    answer_image: values.optionB.image,
                                                    is_correct_answer: values.question_correct_answer == 'B' ? true : false,
                                                },
                                                {
                                                    option: "C",
                                                    content: values.optionC.text,
                                                    answer_image: values.optionC.image,
                                                    is_correct_answer: values.question_correct_answer == 'C' ? true : false,
                                                },
                                                {
                                                    option: "D",
                                                    content: values.optionD.text,
                                                    answer_image: values.optionD.image,
                                                    is_correct_answer: values.question_correct_answer == 'D' ? true : false,
                                                },
                                            ] : [],

                                        };
                                        console.log(feedbackQuestionsValues)
                                        this.props.addFeedbackQuestions(feedbackQuestionsValues).then((res: any) => {

                                        })
                                    }}
                                >
                                    {({
                                        values,
                                        errors,
                                        isSubmitting,
                                        isValidating,
                                        dirty,
                                        touched,
                                        handleBlur,
                                        handleReset,
                                        handleSubmit,
                                        handleChange,
                                        setFieldValue,
                                        setFieldTouched
                                    }) => {

                                        return (
                                            <Form>

                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <FormGroup className="w-100 mb-4">
                                                            <Field
                                                                label={<><span>Grade</span><span className='formmandatorycolor'> *</span></>}
                                                                name="select_grade"
                                                                select
                                                                component={TextField}
                                                                onChange={(e: any, value: any) => {
                                                                    this.getSubjectDropdown(e.target.value);
                                                                    setFieldValue(
                                                                        "select_grade",
                                                                        e.target.value
                                                                    );
                                                                    setFieldValue("select_subject", "");
                                                                    setFieldValue("lessons", "");
                                                                    this.setState({ lessons: [] });
                                                                }}
                                                            >
                                                                {grade && grade.length > 0 && grade.map((item: any) => (
                                                                    <MenuItem value={item.internal_grade_id}>
                                                                        {item.internal_grade_name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Field>
                                                        </FormGroup>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <FormGroup className="w-100 mb-4">
                                                            <Field
                                                                label={<><span>Subject</span><span className='formmandatorycolor'> *</span></>}
                                                                name="select_subject"
                                                                select
                                                                component={TextField}
                                                                onChange={(e: any, value: any) => {
                                                                    this.getLessonDropdown(e.target.value);
                                                                    setFieldValue(
                                                                        "select_subject",
                                                                        e.target.value
                                                                    );
                                                                    setFieldValue("lessons", "");
                                                                }}
                                                            >
                                                                {subject && subject.length > 0 && subject.map((item: any) => (
                                                                    <MenuItem value={item.internal_subject_id}>
                                                                        {item.internal_subject_name}
                                                                    </MenuItem>
                                                                ))}

                                                            </Field>
                                                        </FormGroup>
                                                    </div>


                                                    <div className="col-md-4">
                                                        <FormGroup className="w-100">
                                                            <Field
                                                                label={<><span>Lessons</span><span className='formmandatorycolor'> *</span></>}
                                                                name={`lessons`}
                                                                select
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            >
                                                                {lessons && lessons.length > 0 && lessons.map((item: any) => (
                                                                    <MenuItem value={item.uid} >
                                                                        {item.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Field>
                                                        </FormGroup>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <FormGroup className="w-100">
                                                            <Field
                                                                label={<><span>Question Type</span><span className='formmandatorycolor'> *</span></>}
                                                                select
                                                                name={`question_type`}
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                                onChange={(e: any) => {

                                                                    // Update options and reset selectedExam
                                                                    setFieldValue(`question_type_marks`, '');
                                                                    setFieldValue(`question_type`, e.target.value);
                                                                    setFieldValue(`question_type_name`, this.getQuestionTypeName(e.target.value));
                                                                }}
                                                            >

                                                                {question_type && question_type.length > 0 && question_type.map((item: any) => (
                                                                    <MenuItem value={item.id} >
                                                                        {item.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </Field>
                                                        </FormGroup>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <FormGroup className="w-100">
                                                            <Field
                                                                label={<><span>Marks</span><span className='formmandatorycolor'> *</span></>}
                                                                name={`question_type_marks`}
                                                                select
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                                onChange={(e: any) => {
                                                                    setFieldValue(`question_type_marks`, e.target.value);
                                                                    this.setState({subQuestionImage:[]})
                                                                    handleChange(e);
                                                                    // Update the value based on the selected questionType




                                                                }}
                                                            >
                                                                {this.getMarkOptions(values.question_type).map((item: any) => (

                                                                    <MenuItem value={item.value} >
                                                                        {item.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </Field>
                                                        </FormGroup>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <FormGroup className="w-100">
                                                            <Field
                                                                label={<><span>Difficulty Level</span><span className='formmandatorycolor'> *</span></>}
                                                                name={`difficulty_level`}
                                                                select
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            >
                                                                {difficulty_level && difficulty_level.length > 0 && difficulty_level.map((item: any) => (
                                                                    <MenuItem value={item.uid} >
                                                                        {item.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Field>
                                                        </FormGroup>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="card-box mt-4">
                                                                    <div className="card-body  pb-5">
                                                                        <div>
                                                                            <div className="d-flex mt-1 w-100">
                                                                                <FormGroup className="w-100">
                                                                                    <Field
                                                                                        label={<><span>{this.getQuestionTypeName(values.question_type) == 'ReadPassageQ' ? 'Write Passage' : 'Write Question'}</span><span className='formmandatorycolor'> *</span></>}
                                                                                        type="text"
                                                                                        name={`question`}
                                                                                        component={TextField}
                                                                                        className="textfield__input"
                                                                                        disabled={false}
                                                                                    />
                                                                                </FormGroup>
                                                                            </div>
                                                                            {this.getQuestionTypeName(values.question_type) !== 'MatchPairQ' ?
                                                                            <>
                                                                            <div className="mt-4">
                                                                                {/* <input type="file" accept=".jpg,.jpeg,.png" onChange={(event: any) => {
                                                                                    this.handleQuestionImageChange(event)
                                                                                    setFieldTouched('question_image', true); 
                                                                                    const file = event.target.files[0];
                                                                                    if (file) {
                                                                                        const reader = new FileReader();
                                                                                        reader.onload = (e: any) => {
                                                                                            const base64Image = e.target.result;
                                                                                            //setFieldValue('question_image', reader.result);
                                                                                            setFieldValue('question_image', base64Image);
                                                                                        };
                                                                                        reader.readAsDataURL(file);
                                                                                    }
                                                                                }} />
                                                                                {questionImage && <img src={questionImage} alt="Selected" style={{ maxWidth: '100px', maxHeight: '100px' }} />} */}
                                                                                <QuestionImageSelect setImages={questionImage} setImagesState={this.setImagesState} setfield={setFieldValue} clickImage={this.showImage} />
                                                                            </div>
                                                                            <ErrorMessage name="question_image" component="div" className="errorcolor" />
                                                                            </>
                                                                            : null}
                                                                        </div>

                                                                        {this.getQuestionTypeName(values.question_type) == 'MCQ' ?
                                                                            <>
                                                                                <div className="row">
                                                                                    <div className="col-md-6">
                                                                                        <div className="col-md-12">
                                                                                            <div className="d-flex mt-3">
                                                                                                <div className="mt-4 mr-3 ml-2">
                                                                                                    <Field
                                                                                                        type="radio"
                                                                                                        name={`question_correct_answer`}
                                                                                                        color="success"
                                                                                                        value="A"
                                                                                                        className="bob-radio"
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="mr-3 mt-4">
                                                                                                    A.
                                                                                                </div>
                                                                                                <FormGroup className="w-100">
                                                                                                    <Field
                                                                                                        label={<><span>Write Option A</span><span className='formmandatorycolor'> *</span></>}
                                                                                                        type="text"
                                                                                                        name={`optionA.text`}
                                                                                                        component={
                                                                                                            TextField
                                                                                                        }
                                                                                                        className="textfield__input"
                                                                                                        disabled={false}
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </div>
                                                                                            <div className="mt-4">
                                                                                                {/* <input type="file" accept=".jpg,.jpeg,.png" onChange={(event: any) => {
                                                                                        this.handleImageChange(event, 'A')
                                                                                        setFieldTouched('optionA.image', true); 
                                                                                        const file = event.target.files[0];
                                                                                        if (file) {
                                                                                            const reader = new FileReader();
                                                                                            reader.onload = (e: any) => {
                                                                                                const base64Image = e.target.result;
                                                                                                setFieldValue('optionA.image', base64Image);
                                                                                            };
                                                                                            reader.readAsDataURL(file);

                                                                                        }
                                                                                    }} />
                                                                                    {options.A.image && <img src={options.A.image} alt="Selected" style={{ maxWidth: '100px', maxHeight: '100px' }} />} */}
                                                                                                <OptionImageSelect setImages={this.state.options.A.image} setImagesState={this.setOptionImagesState} setfield={setFieldValue} option={"A"} clickImage={this.showImage} />
                                                                                            </div>
                                                                                            <ErrorMessage name="optionA" component="div" className="errorcolor" />
                                                                                        </div>
                                                                                        <div className="col-md-12">
                                                                                            <div className="d-flex mt-3">
                                                                                                <div className="mt-4 mr-3 ml-2">
                                                                                                    <Field
                                                                                                        type="radio"
                                                                                                        name={`question_correct_answer`}
                                                                                                        value="B"
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="mr-3 mt-4">
                                                                                                    B.
                                                                                                </div>
                                                                                                <FormGroup className="w-100">
                                                                                                    <Field
                                                                                                        label={<><span>Write Option B</span><span className='formmandatorycolor'> *</span></>}
                                                                                                        type="text"
                                                                                                        name={`optionB.text`}
                                                                                                        component={
                                                                                                            TextField
                                                                                                        }
                                                                                                        className="textfield__input"
                                                                                                        disabled={false}
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </div>
                                                                                            <div className="mt-4">
                                                                                                {/* <input type="file" accept=".jpg,.jpeg,.png" onChange={(event: any) => {
                                                                                        this.handleImageChange(event, 'B')
                                                                                        setFieldTouched('optionB.image', true); 
                                                                                        // setFieldValue('optionB.image', event.currentTarget.files[0]);
                                                                                        const file = event.target.files[0];
                                                                                        if (file) {
                                                                                            const reader = new FileReader();
                                                                                            reader.onload = (e: any) => {
                                                                                                const base64Image = e.target.result;
                                                                                                setFieldValue('optionB.image', base64Image);
                                                                                            };
                                                                                            reader.readAsDataURL(file);
                                                                                        }
                                                                                    }
                                                                                    }
                                                                                    />
                                                                                    {options.B.image && <img src={options.B.image} alt="Selected" style={{ maxWidth: '100px', maxHeight: '100px' }} />} */}
                                                                                                <OptionImageSelect setImages={this.state.options.B.image} setImagesState={this.setOptionImagesState} setfield={setFieldValue} option={"B"} clickImage={this.showImage} />
                                                                                            </div>
                                                                                            <ErrorMessage name="optionB" component="div" className="errorcolor" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        <div className="col-md-12">
                                                                                            <div className="d-flex mt-3">
                                                                                                <div className="mt-4 mr-3 ml-2">
                                                                                                    <Field
                                                                                                        type="radio"
                                                                                                        name={`question_correct_answer`}
                                                                                                        value="C"
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="mr-3 mt-4">
                                                                                                    C.
                                                                                                </div>
                                                                                                <FormGroup className="w-100">
                                                                                                    <Field
                                                                                                        label={<><span>Write Option C</span><span className='formmandatorycolor'> *</span></>}
                                                                                                        type="text"
                                                                                                        name={`optionC.text`}
                                                                                                        component={
                                                                                                            TextField
                                                                                                        }
                                                                                                        className="textfield__input"
                                                                                                        disabled={false}
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </div>
                                                                                            <div className="mt-4">
                                                                                                {/* <input type="file" accept=".jpg,.jpeg,.png" onChange={(event: any) => {
                                                                                        this.handleImageChange(event, 'C')
                                                                                        setFieldTouched('optionC.image', true); 
                                                                                        //setFieldValue('optionC.image', event.currentTarget.files[0]);
                                                                                        const file = event.target.files[0];
                                                                                        if (file) {
                                                                                            const reader = new FileReader();
                                                                                            reader.onload = (e: any) => {
                                                                                                const base64Image = e.target.result;
                                                                                                setFieldValue('optionC.image', base64Image);
                                                                                            };
                                                                                            reader.readAsDataURL(file);
                                                                                        }
                                                                                    }} />
                                                                                    {options.C.image && <img src={options.C.image} alt="Selected" style={{ maxWidth: '100px', maxHeight: '100px' }} />} */}
                                                                                    <OptionImageSelect setImages={this.state.options.C.image} setImagesState={this.setOptionImagesState} setfield={setFieldValue} option={"C"} clickImage={this.showImage}/>
                                                                                    </div>
                                                                                    <ErrorMessage name="optionC" component="div" className="errorcolor" />
                                                                                </div>
                                                                                <div className="col-md-12">
                                                                                    <div className="d-flex mt-3">
                                                                                        <div className="mt-4 mr-3 ml-2">
                                                                                            <Field
                                                                                                type="radio"
                                                                                                name={`question_correct_answer`}
                                                                                                value="D"
                                                                                            />
                                                                                        </div>
                                                                                        <div className="mr-3 mt-4">
                                                                                            D.
                                                                                        </div>
                                                                                        <FormGroup className="w-100">
                                                                                            <Field
                                                                                                label={<><span>Write Option D</span><span className='formmandatorycolor'> *</span></>}
                                                                                                type="text"
                                                                                                name={`optionD.text`}
                                                                                                component={
                                                                                                    TextField
                                                                                                }
                                                                                                className="textfield__input"
                                                                                                disabled={false}
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </div>
                                                                                    <div className="mt-4">
                                                                                    {/* <input type="file" accept=".jpg,.jpeg,.png" onChange={(event: any) => {
                                                                                        this.handleImageChange(event, 'D')
                                                                                        setFieldTouched('optionD.image', true); 
                                                                                        //setFieldValue('optionD.image', event.currentTarget.files[0]);
                                                                                        const file = event.target.files[0];
                                                                                        if (file) {
                                                                                            const reader = new FileReader();
                                                                                            reader.onload = (e: any) => {
                                                                                                const base64Image = e.target.result;
                                                                                                setFieldValue('optionD.image', base64Image);
                                                                                            };
                                                                                            reader.readAsDataURL(file);
                                                                                        }
                                                                                    }} />
                                                                                    {options.D.image && <img src={options.D.image} alt="Selected" style={{ maxWidth: '100px', maxHeight: '100px' }} />} */}
                                                                                                <OptionImageSelect setImages={this.state.options.D.image} setImagesState={this.setOptionImagesState} setfield={setFieldValue} option={"D"} clickImage={this.showImage} />
                                                                                            </div>
                                                                                            <ErrorMessage name="optionD" component="div" className="errorcolor" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="text-center mb-3 mr-2 mt-4">
                                                                                    <ErrorMessage name="question_correct_answer" component="div" className="errorcolor" />
                                                                                </div>
                                                                            </> : null}
                                                                        {this.getQuestionTypeName(values.question_type) == 'MatchPairQ' ?
                                                                            <>
                                                                                <div className="row">
                                                                                    <div className="col-md-12">

                                                                                        <div className="d-flex mt-1 w-100">
                                                                                            <FormGroup className="w-100">
                                                                                                <Field
                                                                                                    label={<><span>Answer</span><span className='formmandatorycolor'> *</span></>}
                                                                                                    type="text"
                                                                                                    name={`answer`}
                                                                                                    component={
                                                                                                        TextField
                                                                                                    }
                                                                                                    className="textfield__input"
                                                                                                    disabled={false}
                                                                                                />
                                                                                            </FormGroup>
                                                                                        </div>
                                                                                        {/* <div className="mt-4">
                                                                                            <AnswerImageSelect setImages={answerImage} setImagesState={this.setMatchQImage} setfield={setFieldValue} clickImage={this.showImage} />
                                                                                        </div>
                                                                                        <ErrorMessage name="answer_image" component="div" className="errorcolor" /> */}
                                                                                    </div>
                                                                                </div>
                                                                            </> : null}
                                                                        {this.getQuestionTypeName(values.question_type) == 'ReadPassageQ' ?
                                                                        <>
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <FieldArray name="sub_question_list">
                                                                                {({ push, remove }) => {
                                                                                    const currentMark = Number(values.question_type_marks);
                                                                                    const existingMark  = values.sub_question_list.slice();
                                                                                if (existingMark.length !==  currentMark) {
                                                                                    values.sub_question_list.length = 0;
                                                                                    for (let i = 0; i <  currentMark; i++) {
                                                                                        const existingQues = existingMark[i] || { question: "", question_image: '', marks: 1 };
                                                                                        push(existingQues);
                                                                                        // push({
                                                                                        //     question: "",
                                                                                        //     question_image: '',
                                                                                        //     marks: 1
                                                                                        // })
                                                                                    }
                                                                                }
                                                                                return (
                                                                                    <>
                                                                                    {/* Dynamic FieldArray items based on the selected value */}
                                                                                    {values.sub_question_list.map((item, index) => (
                                                                                        <div className='row' key={index}>
                                                                                        <div className="col-md-12">                                                                                                            
                                                                                                    <div className="d-flex mt-1 w-100">
                                                                                                        <div className="mr-3 mt-4">
                                                                                                            {index + 1}.
                                                                                                        </div>
                                                                                                        <FormGroup className="w-100">
                                                                                                            <Field
                                                                                                                label={<><span>Write Question {index +1} </span><span className='formmandatorycolor'> *</span></>}
                                                                                                                type="text"
                                                                                                                name={`sub_question_list[${index}].question`}
                                                                                                                component={
                                                                                                                    TextField
                                                                                                                }
                                                                                                                className="textfield__input"
                                                                                                                disabled={false}
                                                                                                            />
                                                                                                        </FormGroup>
                                                                                                    </div> 
                                                                                                    {/* <div className="mt-4">
                                                                                                        <ReadPassageSubQuesImageUpload setImages={subQuestionImage?.[index]?.question_image} setImagesState={this.setSubQuesImage} setfield={setFieldValue} clickImage={this.showImage} subQuesImageIndex={index}/>
                                                                                                    </div>
                                                                                                    <ErrorMessage name={`sub_question_list[${index}].question_image`} component="div" className="errorcolor" />                                                                                                             */}
                                                                                                    </div>
                                                                                                </div>
                                                                                    ))}
                                                                                    </>
                                                                                );
                                                                                }}
                                                                            </FieldArray>
                                                                            </div>
                                                                        </div>
                                                                        </> : null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-right mb-3 mr-2 mt-4">
                                                    <Button
                                                        className="btn btn-pink mr-1 ml-1"
                                                        type="submit">Submit</Button>
                                                </div>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={loadingQuestionFull}><SpinnerLoader /></div>
            </div>
        )
    }
}



const mapStateToProps = (state: any) => {
    return {
        loading: state.QuestionBank.loading,
        getYear: state.profile.getYear,
        stopYear: state.profile.yearStatusFails,
        getChangeYearData: state.profile.getYear,
        bluePrintGradeDropdown: state.QuestionBank.bluePrintGradeDropdown,
        bluePrintSubjectDropdown: state.QuestionBank.bluePrintSubjectDropdown,
        bluePrintExamTypeDropdown: state.QuestionBank.bluePrintExamTypeDropdown,
        bluePrintLessonDropdown: state.QuestionBank.bluePrintLessonDropdown,
        bluePrintDifficultyLevelDropdown: state.QuestionBank.bluePrintDifficultyLevelDropdown,
        bluePrintQuestionTypeDropdown: state.QuestionBank.bluePrintQuestionTypeDropdown,
    }
}

export default connect(mapStateToProps, {
    stopYearData,
    fetchBluePrintGradeDropdownGet,
    fetchBluePrintSubjectDropdownGet,
    fetchBluePrintExamTypeDropdownGet,
    fetchBluePrintLessonDropdownGet,
    fetchBluePrintDifficultyLevelDropdownGet,
    fetchBluePrintQuestionTypeDropdownGet,
    addFeedbackQuestions
})(FeedbackQuestionsAdd)