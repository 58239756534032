import React, { Component } from 'react'
import { connect } from 'react-redux';
import BreadCrumb from '../../components/BreadCrumb';
import { Link } from 'react-router-dom';
import CommonLoader from '../../components/CommonLoader';
import { QuestionTypes } from '../../store/question/Types';
import { Question } from '../../router/Roles';
import { fetchQuestionSetPost } from '../../store/question/Actions';
import InfiniteScroll from 'react-infinite-scroll-component';
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import { fetchGetAllClassList } from '../../store/diary/Actions';
import { GetCorrectSubject } from '../../store/subject/Actions';
import { getYearData, stopYearData, stopSubComponentYear } from '../../store/profile/Actions'

export type OwnQuestSetProps = {
  getYear?: any;
  fetchQuestionSetPost: (getPostValue: any) => any;
  GetCorrectSubject: (postValue: any) => void;
  fetchGetAllClassList: (postValue: any) => void;
  getYearData: (postvalue?: any) => any;
  stopSubComponentYear: (postvalue?: any) => any;
  stopYearData: () => any;
  loading: boolean;
  acsOrder: boolean;
  page: number;
  per_page: number;
  totalPage: number;
  records: any;
  total: number;
  getClassList: any;
  getSubjectlist: any;
  stopYear?: any;
  subComponentStatus?: any
}
export class ViewQuestion extends Component<OwnQuestSetProps, QuestionTypes> {
  hasMoreClass: any;
  constructor(props: any) {
    super(props);
    this.state = {
      acsOrder: true,
      academicYear: null,
      search: '',
      hasMore: true,
      page: 1,
      page_no: 1,
      SortOrderData: '',
      OrderNameData: '',
      recordPage: 1,
      sort_by: 'desc',
      sort_class_name: 'fa fa-sort-amount-asc',
      sort_title_name: 'Ascending order'
    }
  }
  componentDidMount(): void {
    window.scrollTo(0, 0);
    this.getUpdateYear()
  }
  componentWillUpdate(nextProps: any, nextState: any) {
    const { stopYear } = this.props;
    if (stopYear === true) {
      this.getUpdateYear(this.props.getYear)
    }
  }
  componentDidUpdate() {
    const { stopYear } = this.props;
    if (stopYear === true) {
      this.props.stopYearData()
    }
  }
  getUpdateYear(getYear?: any) {
    if (getYear && getYear !== undefined && getYear !== null) {
      this.setState({ academicYear: getYear })
      this.getQuestionData(getYear)
    } else {
      this.setState({ academicYear: this.props.getYear })
      this.getQuestionData(this.props.getYear)
    }
  }
  getQuestionData(getYear?: any) {
    this.getQuestionSetDetails(getYear)
    const postValue = {
      academic_year: getYear
    }
    this.props.GetCorrectSubject(postValue);
    this.props.fetchGetAllClassList(postValue);
  }
  getQuestionSetDetails(getYear?: any) {
    const getPostStudent = {
      page_no: 1,
      academic_year: getYear,
      search: this.state.search,
      sort_by: this.state.SortOrderData,
      order_by: this.state.OrderNameData
    }
    this.props.fetchQuestionSetPost(getPostStudent).then((res: any) => {
      this.setState({ hasMore: true, page: 1 })
      this.getViewQuestion()
    });
  }
  getViewQuestion() {
    const { records, getClassList, getSubjectlist } = this.props;
    if (records.length > 0) {
      const getSubjectName: any = getSubjectlist.data;
      if (getSubjectName) {
        records.forEach((items: any) => {
          // get class Name list
          const gradeId: any = getClassList.find((item: any) => item.id === parseInt(items.grade_id))
          if (gradeId) {
            items['class_name'] = gradeId.grade_standard;
          }
          // get Subject name list
          const subjectName: any = getSubjectName.find((item: any) => item.id === parseInt(items.subject_id))
          if (subjectName) {
            items['subject_name'] = subjectName.name;
          }
        })
      }
    }
  }
  showFilterData(getValues: any) {
    const { academicYear, page } = this.state;
    if (getValues.sort_by === 'desc') {
      this.setState({
        acsOrder: false,
        SortOrderData: getValues.sort_by,
        OrderNameData: getValues.order_by
      })
      const postValue = {
        page_no: 1,
        academic_year: academicYear,
        search: this.state.search,
        sort_by: getValues.sort_by,
        order_by: getValues.order_by
      }
      this.setState({
        hasMore: true,
        SortOrderData: getValues.sort_by,
        OrderNameData: getValues.order_by,
        page: 1,
        sort_by: 'asc',
        sort_class_name: 'fa fa-sort-amount-desc',
        sort_title_name: 'Descending order'
      })
      this.props.fetchQuestionSetPost(postValue).then((res: any) => {
        this.getViewQuestion()
      });
    } else {
      this.setState({
        acsOrder: true,
        SortOrderData: getValues.sort_by,
        OrderNameData: getValues.order_by,
      })
      const postValue = {
        page_no: 1,
        academic_year: academicYear,
        search: this.state.search,
        sort_by: getValues.sort_by,
        order_by: getValues.order_by
      }
      this.setState({
        hasMore: true,
        SortOrderData: getValues.sort_by,
        OrderNameData: getValues.order_by,
        page: 1,
        sort_by: 'desc',
        sort_class_name: 'fa fa-sort-amount-asc',
        sort_title_name: 'Ascending order'
      })
      this.props.fetchQuestionSetPost(postValue).then((res: any) => {
        this.getViewQuestion()
      });
    }
  }
  public fetchMoreStudentData = () => {
    const { page, academicYear } = this.state;

    if (this.state.hasMore === true) {
      if (Math.ceil(this.props.total / this.props.per_page) > this.props.page) {
        const postValue = {
          page_no: page + 1,
          academic_year: academicYear,
          sort_by: this.state.SortOrderData,
          order_by: this.state.OrderNameData,
          search: this.state.search
        }
        this.props.fetchQuestionSetPost(postValue).then((res: any) => {
          this.getViewQuestion()
        });
        this.setState({
          page: page + 1
        })
      }

      if (Math.ceil(this.props.total / this.props.per_page) === this.props.page) {
        this.setState({
          hasMore: false,
        })
      }
    }
  }
  private renderQuestionView(records: any) {
    const { sort_by, sort_class_name, sort_title_name } = this.state;
    const { loading } = this.props;
    const loadingQuestionFull = { display: loading ? "block" : "none" };
    return (
      <div>
        <InfiniteScroll
          dataLength={records.length}
          next={this.fetchMoreStudentData}
          hasMore={this.state.hasMore}
          loader={<h4 style={loadingQuestionFull}>Loading...</h4>}
        >
          <table className="table table-striped custom-table table-hover">
            <thead>
              <tr>
                <th>
                  <button onClick={() => this.showFilterData({ sort_by: sort_by, order_by: 'question_name' })}
                    title={sort_title_name} className="headerBold">Name&nbsp;
                    <i className={sort_class_name} aria-hidden="true"></i></button>
                </th>
                <th>
                  <button onClick={() => this.showFilterData({ sort_by: sort_by, order_by: 'grade_id' })}
                    title={sort_title_name} className="headerBold">Class Name&nbsp;
                    <i className={sort_class_name} aria-hidden="true"></i></button>
                </th>
                <th>
                  <button onClick={() => this.showFilterData({ sort_by: sort_by, order_by: 'subject_id' })}
                    title={sort_title_name} className="headerBold">Subject&nbsp;
                    <i className={sort_class_name} aria-hidden="true"></i></button>
                </th>
                <th>
                  Questions
                </th>
                <th>
                  Total Quizzes
                </th>
                <th>
                  <button onClick={() => this.showFilterData({ sort_by: sort_by, order_by: 'posted_by' })}
                    title={sort_title_name} className="headerBold">Created By&nbsp;
                    <i className={sort_class_name} aria-hidden="true"></i></button>
                </th>
                <th>
                <button onClick={() => this.showFilterData({ sort_by: sort_by, order_by: 'created_at' })}
                    title={sort_title_name} className="headerBold">
                      Created Time&nbsp;
                    <i className={sort_class_name} aria-hidden="true"></i></button></th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {records.length > 0 ?
                records.map((items: any) => (
                  <tr>
                    <td >{items['question_name'] || '-'}</td>
                    <td >{items['class_name'] || '-'}</td>
                    <td >{items['subject_name'] || '-'}</td>
                    <td >{items['question_count'] || '0'}</td>
                    <td >{items['quiz_count'] || '0'}</td>
                    <td >{items['posted_by'] || '-'}</td>
                    <td >{items['date_time'] || '-'}</td>
                    <td>
                      {items.is_active ?
                        <Link to={`/question/${items.id}`}>
                          <button className="btn btn-primary btn-xs"
                            title="View"><i className="fa fa-eye" aria-hidden="true"></i></button></Link> :
                        <button className="btn btn-disable btn-xs"
                          title="View"><i className="fa fa-eye" aria-hidden="true"></i></button>}
                    </td>
                  </tr>
                ))
                : <CommonLoader />}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    )
  }

  handleInputQuestionSet = (e: any) => {
    const { value } = e.target;
    const { academicYear } = this.state;
    const getSearchValue = value;
    if (getSearchValue) {
      const postSearchValue = {
        page_no: 1,
        search: getSearchValue,
        academic_year: academicYear,
        sort_by: this.state.SortOrderData,
        order_by: this.state.OrderNameData
      }
      this.props.fetchQuestionSetPost(postSearchValue).then((res: any) => {
        this.getViewQuestion()
      });
      this.setState({
        hasMore: true,
        page: 1,
        search: getSearchValue
      })
      this.hasMoreClass = true;
    } else {
      const postSearchValue = {
        page_no: 1,
        search: '',
        academic_year: academicYear,
        sort_by: this.state.SortOrderData,
        order_by: this.state.OrderNameData
      }
      this.props.fetchQuestionSetPost(postSearchValue).then((res: any) => {
        this.getViewQuestion()
      });
      this.setState({
        hasMore: true,
        page: 1,
        search: ''
      })
      this.hasMoreClass = true;
    }
  }

  refreshQuestionSetTable = () => {
    const { academicYear } = this.state;
    this.setState({
      search: ''
    }, () => {
      this.getQuestionSetDetails(academicYear)
    });  
  }
  onOpenUploadOption = () => {

  }
  render() {
    const { loading } = this.props;
    const loadingQuestionFull = { display: loading ? "block" : "none" };

    return (
      <div>
        <div className="page-wrapper">
          <div className="page-content-wrapper">
            <div className="page-content pt-3">
              <BreadCrumb
                titleName={['Question Set']}
                homeName={['Home']}
                url={['dashboard']}
                mainPageTitle={['Question Set']} />
              <div className="row">
                <div className="col-md-12 mb-3">
                  <div className="mr-4 mt-2">
                    <h4 className="pull-right">Total: {this.props.total}</h4>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-topline-red">

                    <div className="card-head">
                      <header>
                        <Link to={Question.AddQuestion}>
                          <button className="btn btn-pink">Add Question Set</button>
                        </Link>
                        <Link to={Question.UploadQuestionSet} className="btn btn-pink ml-2 mt-1" title="CSV File Upload">
                          Upload Question Set
                        </Link>
                        <Link to={Question.ImportQuestion} className="ml-2 mt-1" title="Import Question">
                          <button className="btn btn-pink">Import Question Set</button>
                        </Link>
                      </header>
                      <div className="tools">
                        <div className="d-flex">
                          <div>
                            <input
                              placeholder="Search"
                              value={this.state.search}
                              name="search"
                              className="form-control"
                              onChange={this.handleInputQuestionSet}
                            />
                          </div>
                          <div className="ml-2 mt-2">
                            <span className="fa fa-repeat btn-color box-refresh pointer" onClick={this.refreshQuestionSetTable}></span>
                            <span className="t-collapse btn-color fa fa-chevron-up pointer" ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body no-padding height-9">
                      <div className="row">
                        <div className="table-responsive">

                          {this.renderQuestionView(this.props.records)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={loadingQuestionFull}><SpinnerLoader /></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    loading: state.questionset.loading,
    getYear: state.profile.getYear,
    stopYear: state.profile.yearStatusFails,
    subComponentStatus: state.profile.subComponentStart,
    total: state.questionset.total,
    per_page: state.questionset.per_page,
    records: state.questionset.records,
    page: state.questionset.page,
    totalPage: state.questionset.totalPage,
    getClassList: state.diary.gradelist,
    getSubjectlist: state.subjects.category,
  }
}

const mapDispatchToProps = {
  fetchQuestionSetPost,
  fetchGetAllClassList,
  GetCorrectSubject,
  stopYearData,
  getYearData,
  stopSubComponentYear
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewQuestion)
