import React, { createRef } from 'react'
import { connect } from 'react-redux'
import BreadCrumb from '../../components/BreadCrumb';
import { diaryList } from '../../store/diary/Types';
import { StudentList } from '../../components/StudentList';
import ImageUpload from '../../components/ImageUpload';
import {
  fetchDiaryPost,
  fetchGetAllClassList,
  fetchGetStudent,
  addDiaryPost,
  fetchSearchAllStudent,
  PutDiaryReplay
} from '../../store/diary/Actions';
import CommonLoader from '../../components/CommonLoader';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Checkbox, TextField as MTextField } from '@material-ui/core';
import { Button, FormGroup, MenuItem } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Field, Form, Formik, FormikProps } from 'formik';
import { TextField } from 'formik-material-ui';
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import { DiaryValidation, DiaryReplyData } from './Diaryvalidation';
import './DiaryStyle.scss';
import { DIARYAPI } from '../../services/Config';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DiaryDropDown } from '../../store/diary/Actions';
import moment from 'moment';
import { studentValida, FormvalidationMessage, imageValidateS } from '../../services/Constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import toaster from "toasted-notes";
import { notificationMsg } from '../../services/Constants';
import history from '../../History';
import { stopYearData } from '../../store/profile/Actions'
import { editorImageUpload } from '../../store/student/Actions';
import FileUploadPlugin from '../../components/FileUploadPlugin';

export interface PostsDiaryProps {
  getYear?:any;
  DiaryDropDown: () => void;
  fetchDiaryPost: (getDiary: any, url: any) => any;
  fetchGetAllClassList: (postPage: any) => any;
  fetchGetStudent: (getStudentList: any) => any;
  addDiaryPost: (addDiaryPost: any, url: any) => any;
  fetchSearchAllStudent: (searchStudent: any) => any;
  PutDiaryReplay: (postValue: any) => any;
  editorImageUpload:(data:any, url:any)=>void;
  stopYearData:()=>any;
  loading: boolean
  DiaryDetails: any;
  AllClassList: any;
  AllStudentList: any;
  page: number;
  per_page: number;
  totalPage: number;
  records: any;
  total: number;
  getDiaryImageURL: any;
  loadingStudent: boolean;
  getResponseClassList: any;
  getResponseStudent: any;
  getSearchStudentList: any
  errorMessage: any;
  getProfile: any;
  getDropDown: any;
  stopYear?:any;
  recordLoader?:boolean;
  isBoardError?:boolean;
}
export interface AddDiary {
  diray_status: string,
  your_title: string,
  message: string,
  diaryMessageDetails: boolean | null
}
export interface ReplayData {
  replayMessage: any
}
const initialDiaryValues: AddDiary = {
  diray_status: '',
  your_title: '',
  message: '',
  diaryMessageDetails: true
}
const initialDiaryReply: ReplayData = {
  replayMessage: ''
}
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export class ViewDiaryDetails extends React.Component<PostsDiaryProps, diaryList> {
  formikDiary: any;
  formikReplyDiary: any;
  getAllStudentDetails: any = [];
  getStudentList: any;
  getClassList: any = [];
  postStudentList: any = [];
  getDiaryImage: any;
  editorRef: any;
  state = {
    checkedListAll: [],
    ItemsChecked: false,
    ShowStudentList: false,
    academicYear:null,
    images: [],
    setImages: [],
    maxNumber: 65,
    AllStudent: [],
    getClass: '1st',
    page: 1,
    search: '',
    SortOrderData: '',
    OrderNameData: '',
    SelectValue: 'pl-2',
    standardlist: [],
    studentlist: [],
    allstudentslist: [],
    currentCheckboxAllStudent: false,
    currentSelectedStandard: [],
    selectedDiariesList: [],
    getDiaryImage: '',
    hasMore: true,
    message: '',
    diaryMessageDetails: true,
    studentAutoCompleteData: [],
    viewDiaryData:[],
    isExpanded:false
  };
  constructor(props: any) {
    super(props);
    this.handleCheckStudent = this.handleCheckStudent.bind(this);
    this.editorRef = createRef();
  }
  componentDidMount(): void {
    window.scrollTo(0, 0);
    this.getUpdateYear()
  }
  componentWillUpdate(nextProps:any, nextState:any) {
    const {stopYear} = this.props;
    if(stopYear === true){
      this.getUpdateYear(this.props.getYear)
    }
  }
  componentDidUpdate() {
    const {stopYear} = this.props;
    if(stopYear === true){
      this.props.stopYearData()
    }
    if (this.props.getDiaryImageURL) {
      const getUrlpath = this.props.getDiaryImageURL.url;
      if (getUrlpath === undefined) {
        this.getDiaryImage = '';
      } else {
        getUrlpath.forEach((items: any) => {
          this.getDiaryImage = items;
        })
      }
    }

    if (this.props.errorMessage.data) {
      this.formikDiary.setFieldError('your_title', this.props.errorMessage.data.title)
      this.formikDiary.setFieldError('diray_status', this.props.errorMessage.data.diray_status)
      this.formikDiary.setFieldError('message', this.props.errorMessage.data.message)
    }
  }
    getUpdateYear(getYear?:any){
        if(getYear && getYear !== undefined && getYear !== null){
            this.setState({academicYear:getYear})
            this.getDiaryDetails(getYear)
          }else {
            this.setState({academicYear:this.props.getYear})
            this.getDiaryDetails(this.props.getYear)
          }
    }
  getDiaryDetails(getYear?:any){
    this.props.DiaryDropDown();
    const { search, SortOrderData, OrderNameData } = this.state;
    const loadMoreType = {
      academic_year: getYear
    }
    this.props.fetchGetAllClassList(loadMoreType).then((res: any) => {
      const { AllClassList, AllStudentList } = this.props
      if (AllClassList.length > 0) {
        this.setState({ currentSelectedStandard: AllClassList[0] });
        this.setState({ standardlist: AllClassList });
        this.props.fetchGetStudent({
          class_id: AllClassList[0].id,
          academic_year: getYear
        })
      }
      this.setState({ studentlist: AllStudentList });

    })
    const postValue = {
      page_no: 1,
      search: search,
      sort_by: SortOrderData,
      order_by: OrderNameData,
      academic_year: getYear
    }
    this.setState({
      page: 1
    })
    this.props.fetchSearchAllStudent({
      search: ' ',
      academic_year: getYear
    })
    this.props.fetchDiaryPost(postValue, DIARYAPI.DIARY).then((res: any) => {
      window.scrollTo(0, 0);
      this.getRecordData()
    });
  }

  standardonSelect(e: any, data: any) {
    const { selectedDiariesList, academicYear } = this.state;
    const { AllStudentList } = this.props;
    const { items } = data;
    const standardlistele: any = document.getElementById("standardlist");
    if (standardlistele.querySelector('.active')) {
      standardlistele.querySelector('.active').classList.remove('active');
    }
    this.setState({ currentSelectedStandard: items });
    e.target.classList.add('active')
    const getStudent = {
      class_id: items.id,
      academic_year: academicYear
    }
    this.props.fetchGetStudent(getStudent).then((res: any) => {
      this.setState({ studentlist: AllStudentList });
      this.unSelectAll();
      const findDiariesList = selectedDiariesList.find((item: any) => item.id === getStudent.class_id);
      if (findDiariesList) {
        this.selectPreviousSelectedStudents(findDiariesList);
      }
    });
  }

  handleCheckStudent(e: any) {
    const { selectedDiariesList, currentSelectedStandard } = this.state;
    const { AllStudentList } = this.props;
    const standardId: any = currentSelectedStandard;
    const checkbox: any = document.getElementById('student_' + e.currentTarget.dataset.id);
    if (checkbox.checked) {
      checkbox['checked'] = true;
      const availableDiareslist = selectedDiariesList.filter((item: any) => item.id === standardId.id);
      if (availableDiareslist.length) {
        const findDiariesList = selectedDiariesList.map((item: any) => {
          if (item.id === standardId.id) {
            item.studentslist.push(AllStudentList.filter((item: any) => item.id === parseInt(e.currentTarget.dataset.id))[0])
            if (item.studentslist.length === AllStudentList.length) {
              const selectAllCheckbox: any = document.getElementById('student_all');
              selectAllCheckbox['checked'] = true;
              item.all = true;
              checkbox['checked'] = true;
            }
          }
          return item;
        });
        this.setState({ selectedDiariesList: findDiariesList });
      }
      else {
        const selectedAllStandardStudents: any = currentSelectedStandard;
        selectedAllStandardStudents['all'] = false;
        selectedAllStandardStudents['studentslist'] = AllStudentList.filter((item: any) => item.id === parseInt(e.currentTarget.dataset.id));
        if(AllStudentList.length == 1){
          selectedAllStandardStudents['all'] = true;
          const selectAllCheckbox: any = document.getElementById('student_all');
          selectAllCheckbox['checked'] = true;
        }
        this.setState({ selectedDiariesList: [...selectedDiariesList, selectedAllStandardStudents] })
      }

    } else {
      const findDiariesList = selectedDiariesList.map((item: any) => {
        if (item.id === standardId.id) {
          let studentslist;
          if (item.all) {
            studentslist = AllStudentList.filter((item: any) => item.id !== parseInt(e.currentTarget.dataset.id))
          } else {
            studentslist = item.studentslist.filter((item: any) => item.id !== parseInt(e.currentTarget.dataset.id))
          }
          item.studentslist = studentslist;
          item.all = false;
        }
        return item;
      })
      this.setState({ selectedDiariesList: findDiariesList });
      this.setState({ currentCheckboxAllStudent: false });
      const selectAllCheckbox: any = document.getElementById('student_all');
      selectAllCheckbox['checked'] = false;
      checkbox['checked'] = false;
    }
  }

  selectPreviousSelectedStudents(data: any) {
    const { AllStudentList } = this.props;
    const { all } = data;
    const selectAllCheckbox: any = document.getElementById('student_all');
    if (all) {

      AllStudentList.forEach((item: any, index: any) => {
        const checkbox: any = document.getElementById('student_' + item.id);
        checkbox['checked'] = true;
      })

      selectAllCheckbox['checked'] = true;
      this.setState({ currentCheckboxAllStudent: true })
    } else {

      data.studentslist.forEach((item: any, index: any) => {
        const checkbox: any = document.getElementById('student_' + item.id);
        checkbox['checked'] = true;
      })
      if (data.studentslist.length === AllStudentList.length) {
        const selectAllCheckbox: any = document.getElementById('student_all');
        selectAllCheckbox['checked'] = true;
      }
    }

  }

  //This is function used to search values
  checkValuelist = (e?: any, data?: any, reason?: any, handleCheck?: any) => {
    const { selectedDiariesList, standardlist } = this.state;
    if (selectedDiariesList) {
      const getStudentData: any = [];
      selectedDiariesList.forEach((items: any, index: any) => {
        if (items.studentslist) {
          items.studentslist.forEach((items: any, index: any) => {
            getStudentData.push(items)
          })
        }
      })
      if (getStudentData.length > 0) {
        this.setState({ studentAutoCompleteData: getStudentData })
      } else {
        this.setState({ studentAutoCompleteData: [] })
      }
    }
    if (reason === "clear") {
      const removeSelectedStudent: any = []
      this.setState({ selectedDiariesList: removeSelectedStudent });
    }
    else if (reason === "remove-option") {
      const selectedStudent = data;
      if (selectedStudent.length > 0) {
        selectedStudent.forEach((selStudent: any, index: any) => {
          const availableDiareslist = selectedDiariesList.filter((item: any) => item.id === parseInt(selStudent.studentclass_details[0].class_id));
          if (availableDiareslist.length) {
            const findDiariesList = selectedDiariesList.map((item: any) => {
              if (item.id === parseInt(selStudent.studentclass_details[0].class_id)) {
                const studentexists = item.studentslist.filter((d: any) => d.id === parseInt(selStudent.id));
                if (!studentexists.length) {
                  const checkbox: any = document.getElementById('student_' + selStudent.id);
                  // item.studentslist.push(selStudent);
                  item.studentslist.push(selectedStudent.filter((item: any) => item.id === parseInt(studentexists.id)))
                  if (checkbox) checkbox['checked'] = false;
                }
                else {
                  item.studentslist.splice(index, 1)
                }
              }
              return item;

            });
            this.setState({ selectedDiariesList: findDiariesList });
          }
        })
      } else {
        this.setState({ selectedDiariesList: [] });
      }

    } else if (reason === "select-option") {
      const selectedStudent = data;
      selectedStudent.forEach((selStudent: any, index: any) => {
        const availableDiareslist = selectedDiariesList.filter((item: any) => item.id === parseInt(selStudent.studentclass_details[0].class_id));
        if (availableDiareslist.length) {
          const findDiariesList = selectedDiariesList.map((item: any) => {
            if (item.id === parseInt(selStudent.studentclass_details[0].class_id)) {
              const studentexists = item.studentslist.filter((d: any) => d.id === parseInt(selStudent.id));
              if (!studentexists.length) {
                const checkbox: any = document.getElementById('student_' + selStudent.id);
                item.studentslist.push(selStudent);
                if (checkbox) checkbox['checked'] = true;
              }
            }
            return item;
          });
          this.setState({ selectedDiariesList: findDiariesList });
        } else {
          const selectedClass = standardlist.filter((item: any) => item.id === parseInt(selStudent.studentclass_details[0].class_id))[0];
          const selectedAllStandardStudents: any = selectedClass;
          selectedAllStandardStudents['all'] = false;
          selectedAllStandardStudents['studentslist'] = [selStudent]
          const checkbox: any = document.getElementById('student_' + selStudent.id);
          if (checkbox) checkbox['checked'] = true;
          this.setState({ selectedDiariesList: [...selectedDiariesList, selectedAllStandardStudents] })
        }
      })

    }
  }
  unSelectAll() {
    // unseleted all
    const { AllStudentList } = this.props;
    let selectAllCheckbox: any = document.getElementById('student_all');
    AllStudentList.forEach((item: any, index: any) => {
      const checkbox: any = document.getElementById('student_' + item.id);
      checkbox['checked'] = false;
    })
    this.setState({ currentCheckboxAllStudent: false })
    selectAllCheckbox['checked'] = false;
  }

  SelectAll() {
    const { AllStudentList } = this.props;
    const selectAllCheckbox: any = document.getElementById('student_all');
    AllStudentList.forEach((item: any, index: any) => {
      let checkbox: any = document.getElementById('student_' + item.id);
      checkbox['checked'] = true;
    })
    selectAllCheckbox['checked'] = true;
    this.setState({ currentCheckboxAllStudent: true })
  }

  selectAllStudents() {
    const { AllStudentList } = this.props;
    const { currentSelectedStandard, selectedDiariesList } = this.state;
    if (!this.state.currentCheckboxAllStudent) {
      if (AllStudentList.length === 0) {
        this.unSelectAll();
      }
      else {
        // Selected All
        this.SelectAll();
        const selectedAllStandardStudents: any = currentSelectedStandard;
        selectedAllStandardStudents['all'] = true;
        selectedAllStandardStudents['studentslist'] = AllStudentList; // if you want to add all studentlist in add AllStudent array
        const filteredPeople = selectedDiariesList.filter((item) => item !== currentSelectedStandard);
        this.setState({ selectedDiariesList: [...filteredPeople, selectedAllStandardStudents] })

      }
    } else {
      // unseleted all
      this.unSelectAll();
      const filteredPeople = selectedDiariesList.filter((item) => item !== currentSelectedStandard);
      this.setState({ selectedDiariesList: filteredPeople })
    }
  }
  showStudentList = (e: any, getValue: any) => {
    if(!this.state.ShowStudentList){
    if (getValue === true) {
      this.setState({ ShowStudentList: getValue })
    } else {
      this.setState({ ShowStudentList: false })
    }
    setTimeout(() => {
      const standardlistele: any = document.getElementById("standardlist");
      if (standardlistele.firstChild === null) {
        toaster.notify(FormvalidationMessage.addClassStudent, {
          position: 'top',
          duration: notificationMsg.errorNotificationDuration
        });
        history.push('/');
      } else {
        standardlistele.firstChild.classList.add('active')
      }
    }, 500);
  }
  }
  HiddenStudentList(getValue: any) {
    this.setState({ ShowStudentList: getValue })
  }
  onSubmitDiary = (values: any, resetForm:any) => {
    const {academicYear} = this.state;
    const getProfile = this.props.getProfile;
    var today = new Date();
    const { selectedDiariesList, message } = this.state;
    this.getClassList = []
    this.postStudentList = []
    let studentList: any;
    selectedDiariesList.forEach((items: any, index: any) => {
      items.studentslist.forEach((students: any) => {
        studentList = {
          student_id: students.id,
          student_name: students.student_name,
          parent_id: students.user_id
        }
        this.postStudentList.push(studentList)
      })
      const classlist = {
        class_id: items.id,
        is_all: items.all,
        student_list: this.postStudentList
      }
      this.getClassList.push(classlist)
      if (items.studentslist.length === this.postStudentList.length) {
        this.postStudentList = []
      }
    })
    if (message) {
      const postValue: any = {
        academic_year: academicYear,
        school_id: getProfile.school_id,
        class_list: this.getClassList,
        selected_value: "sdfsdfsdfsd",
        diary_type: values.diray_status,
        title: values.your_title,
        message: this.state.message,
        images: this.getDiaryImage,
        date: today,
        posted_by: getProfile.firstname + ' ' + getProfile.lastname
      }
      this.props.addDiaryPost(postValue, DIARYAPI.DIARY).then((res:any)=>{
      const { search, SortOrderData, OrderNameData } = this.state;
      const {isBoardError} = this.props;
          if(!isBoardError){
            // const postValue = {
            //   page_no: 1,
            //   search: search,
            //   sort_by: SortOrderData,
            //   order_by: OrderNameData,
            //   academic_year: academicYear
            // }
            // this.setState({
            //   page: 1
            // })
            // this.props.fetchDiaryPost(postValue, DIARYAPI.DIARY).then((res: any) => {
            //   window.scrollTo(0, 0);
            //   this.getRecordData()
            // });
            this.getDiaryDetails(academicYear)
            resetForm()
            this.setState({ isExpanded:false, currentSelectedStandard:[], selectedDiariesList:[],  ShowStudentList: false,  setImages: [], currentCheckboxAllStudent: false })
            if (this.editorRef.current) {
              this.editorRef.current.editor.setData('');
            }
          }
       
      });
    }

  }

  setImagesState = (setImages:any) => {
    this.setState({ setImages });
  };

  replaceDomain = (message:string) => {
   const getLocation = function(href:string) {
      var l = document.createElement("a");
      l.href = href;
      return l;
  };
    const parser = new DOMParser()
    const doc = parser.parseFromString(message,'text/html')
    const images = doc.querySelectorAll('img')
    images.forEach((img:any) => 
    {
      const location = getLocation(img.src)
      const baseUrl = location.protocol+'//'+location.hostname+':'+location.port
      img.src = img.src.replace(baseUrl, process.env.REACT_APP_API_URL)
      
    })
    return doc.body.innerHTML
  }
  public fetchMoreDiaryData = () => {
    const { page, search, SortOrderData, OrderNameData, academicYear } = this.state;
    if (this.state.hasMore === true) {
      if (Math.ceil(this.props.total / this.props.per_page) > this.props.page) {
        const postValue = {
          page_no: page + 1,
          search: search,
          sort_by: SortOrderData,
          order_by: OrderNameData,
          academic_year: academicYear
        }
        this.setState({
          page: this.state.page + 1
        })
        this.props.fetchDiaryPost(postValue, DIARYAPI.DIARY).then((res:any)=>{
          this.getRecordData()
        });
      
      }

      if (Math.ceil(this.props.total / this.props.per_page) === this.props.page) {
        this.setState({
          hasMore: false,
        })
      }
    }
  }
  getCkEditorValue = (editordata: any) => {
    if (editordata) {
      this.setState({ message: this.addSpaceAroundLinks(editordata) })
      this.state.message.length > 0 ?
        this.setState({ diaryMessageDetails: true }) :
        this.setState({ diaryMessageDetails: false })
    }
    else {
      this.setState({ diaryMessageDetails: false })
    }

  }
  addSpaceAroundLinks(data: any) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(data, "text/html");
    const links = doc.querySelectorAll("a");
    links.forEach((link: any) => {
      link.innerHTML = " " + link.innerHTML + " ";
    });
    return doc.body.innerHTML;
  }
getRecordData(){
  const { records, getDropDown } = this.props;
  const { standardlist } = this.state;
  if (records.length > 0 && records !== undefined && records !== null && records) {
    records.forEach((items: any) => {
      const getClass = items.class_list;
      const getDiaryType: any = getDropDown.find((item: any) => item.id === parseInt(items.diary_type))
      if (getDiaryType) {
        items.diary_type = getDiaryType.value;
      }
      if (getClass) {
        const studentDetails:any = [];
        getClass.forEach((ClassList: any, index:any) => {
          if(ClassList.is_all === false){
            studentDetails.push(ClassList)
          }

          if (standardlist.length > 0) {
            let getClassList: any = standardlist.find((item: any) => item.id === parseInt(ClassList.class_id))
            if (getClassList) {
              ClassList["grade_standard"] = getClassList.grade_standard;
            }
          }
        })
        items['student_name_list'] = studentDetails;
      }
    })
    if(records){
      records.forEach((diaryItems:any)=>{
          const studentNameList:any = [];
          diaryItems.class_list.forEach((classItem:any)=>{
            classItem.student_list.forEach((student:any)=>{
              studentNameList.push({
                student_name: student.student_name,
                class_name: classItem.class_name
              })
            })
          })
        diaryItems['student_name_false'] = studentNameList;
      })
      
    }
    this.setState({viewDiaryData:records})
  }else {
    this.setState({viewDiaryData:[]})
  }
}

handleAccordionChange = () => {
  this.setState(prevState => ({
    isExpanded: !prevState.isExpanded
  }));
};

  render() {
    const { loading, loadingStudent, getSearchStudentList, getDropDown,recordLoader} = this.props;
    const loadingDiary = { display: loading || loadingStudent ? "block" : "none" };
    const recordLoaderVisibility = { display: recordLoader ? "block" : "none" };
    const { standardlist, selectedDiariesList, ShowStudentList, diaryMessageDetails, viewDiaryData, isExpanded } = this.state;
    let getStudentList: any;
    let studentAutoComplete: any;
    if (this.props.AllStudentList) {
      getStudentList = this.props.AllStudentList;
    }
    if(selectedDiariesList && selectedDiariesList !== undefined && selectedDiariesList !== null && selectedDiariesList.length > 0){
      let studentSelectList:any = []
      selectedDiariesList.forEach((items:any)=>{
        items.studentslist.forEach((studentName:any)=>{
          studentSelectList.push(studentName)
        })        
      })
      studentAutoComplete = studentSelectList;
    }else {
      studentAutoComplete = []
    }
    return (
      <div>
        <div className="page-wrapper">
          <div className="page-content-wrapper">
            <div className="page-content pt-3">
              <BreadCrumb
                titleName={['Diary']}
                homeName={['Home']}
                url={['dashboard']}
                mainPageTitle={['Diary']} />

              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                  <Accordion expanded={isExpanded} onChange={this.handleAccordionChange}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <label className="font-style">Add Diary</label>
                      </AccordionSummary>
                      <AccordionDetails>

                        <div className="col-md-12">
                          <Formik
                            ref={node => this.formikDiary = node}
                            validationSchema={DiaryValidation}
                            initialValues={initialDiaryValues}
                            onSubmit={(values, { resetForm }) => {
                              this.onSubmitDiary(values, resetForm)
                            }}
                            render={({
                              values, errors, isSubmitting, isValidating, dirty, touched, handleSubmit, setFieldValue
                            }: FormikProps<any>) => {
                              let getStudentDetails: any = false;
                              let selectStudentList: any = false;
                              if(selectedDiariesList.length){
                                selectedDiariesList.forEach((items:any, index:any)=>{
                                  items.studentslist.length > 0 ? 
                                  selectStudentList = true
                                  :selectStudentList = false
                                })
                              }
                              selectedDiariesList.length > 0 && selectStudentList ?
                                getStudentDetails = true :
                                getStudentDetails = false
                              const isDiaryEmpty = (!values.diray_status
                                || !values.your_title
                                || !this.state.message);
                              return (
                                <Form>
                                  <FormGroup>
                                    <Field
                                      label={<><span>Category Type</span><span className='formmandatorycolor'> *</span></>}
                                      name="diray_status"
                                      select
                                      component={TextField}
                                      className="textfield__input mb-3"
                                      fullwidth="true"
                                      disabled={false}
                                      onClick={(e: any) => this.showStudentList(e, true)}
                                      id="outlined-required"
                                    >
                                      {getDropDown ?
                                        getDropDown.map((items: any) => (
                                          <MenuItem value={items.id}>{items.value}</MenuItem>
                                        ))
                                        : null}
                                    </Field>
                                  </FormGroup>
                                  {/* here print the select values */}
                                  <div>
                                    {/* {this.getAllStudentDetails ? */}
                                    <div className="row">
                                      <div className="col-12">
                                        {selectedDiariesList ?
                                          <>
                                            {selectedDiariesList.map((item: any, index: any) => (
                                              <>
                                                {item.all ?
                                                  <span className="btn btn-circle btn-circle btn-info disabled mr-2 mb-3" key={index}>{item.grade_standard} </span>
                                                  : <>{item.studentslist.length > 0 && item.studentslist !== undefined ?
                                                    
                                                    item.studentslist.map((slist: any, index: any) => (
                                                    <span className="btn btn-circle btn-circle btn-danger disabled mr-2 mb-3" key={index}>
                                                      <span className="userNameTitle">{slist.student_name}</span> ({item.grade_standard})</span>
                                                  ))
                                                  :null}</>
                                                }

                                              </>
                                            ))}
                                          </> : 'No Selected'}
                                      </div>
                                    </div>
                                    {/* This is the function used to multiple student list view on autocomplete option */}
                                    {ShowStudentList ?
                                      <div>
                                       <>		
                                          {getStudentDetails ?		
                                            null		
                                            : <span className="diaryerrorcolor">{studentValida.diraySelectStudent}</span>}		
                                        </>
                                        <div className="row mb-3 p-3">
                                          {/* List the class details list */}
                                          <div className="col-md-6">
                                            <ul className="list-group docListWindow small-slimscroll-style autoselection" id="standardlist">
                                              {standardlist ?
                                                <>
                                                  {standardlist.map((items: any, index: any) => (
                                                    <li className="list-group-item pointer" key={index}
                                                      onClick={(e) => this.standardonSelect(e, { items, index })}>{items.grade_standard}
                                                      <span className="icon-angle-right float-right">&gt;</span>
                                                    </li>
                                                  ))}
                                                </>
                                                : <CommonLoader />}
                                            </ul>
                                          </div>
                                          {/* This is the list of student details list */}
                                          <div className="col-md-6">
                                            <ul className="docListWindow small-slimscroll-style autoselection">
                                              <li className="title-sticky list-group-item" onClick={() => this.selectAllStudents()} >
                                                <div className="form-check">
                                                  <input type="checkbox" className="form-check-input" defaultChecked={this.state.currentCheckboxAllStudent} id={'student_all'} />
                                                  <label className="form-check-label" htmlFor="exampleCheck1">Select All</label>
                                                </div>
                                              </li>
                                              <StudentList studentlist={getStudentList} handleCheckStudent={this.handleCheckStudent} />
                                            </ul>
                                          </div>
                                        </div>
                                        {/* This is the function called hidden studnet list*/}
                                        {/* <div className="mt-3 text-center">
                                          <button className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-pink"
                                            onClick={() => this.HiddenStudentList(false)}>
                                            <i className="fa fa-angle-up" aria-hidden="true"></i>
                                          </button>
                                        </div> */}
                                      </div>
                                      : null}
                                    <div className="row  pt-3">
                                      <div className="col-md-12">
                                        <FormGroup>
                                          <Field
                                            label={<><span>Your Title</span><span className='formmandatorycolor'> *</span></>}
                                            type="text"
                                            name="your_title"
                                            component={TextField}
                                            className="textfield__input mb-3"
                                            disabled={false}
                                          />
                                        </FormGroup>
                                        <div className="mt-4">
                                          <CKEditor
                                            editor={ClassicEditor}
                                            data=""
                                            onReady={(editor: any) => {
                                              // You can store the "editor" and use when it is needed.
                                              editor.plugins.get('FileRepository').createUploadAdapter = (loader:any) => new FileUploadPlugin(loader, this.props, DIARYAPI.DIARYAPIIMAGEUPDATE);
                                              this.editorRef.current = { editor };
                                            }}
                                            config={{
                                              link: {
                                                defaultProtocol: "http://",
                                              },
                                              image: {
                                                upload: {
                                                    types: ['jpg','jpeg','png','JPG','JPEG','PNG'],  // Only allow JPG/JPEG images
                                                }
                                              },
                                            }}
                                            onChange={(event: any, editor: any) => {
                                              const data = editor.getData();
                                              this.getCkEditorValue(data)
                                            }}
                                            onBlur={(event: any, editor: any) => {
                                            }}
                                            onFocus={(event: any, editor: any) => {
                                            }}
                                          />
                                        </div>
                                        <>
                                          {diaryMessageDetails ?
                                            null
                                            : <span className="diaryerrorcolor">{studentValida.diaryMessage}</span>}
                                        </>
                                      </div>

                                    </div>
                                    {/* This is the tag use import multiple image upload  */}
                                    <div className="row  pt-3">
                                      <div className="col-md-12">
                                        <div className="pos-rel text-center  pl-0">
                                          <ImageUpload URLLink={DIARYAPI.DIARYAPIIMAGEUPDATE} setImages={this.state.setImages} setImagesState={this.setImagesState}/>
                                        </div>
                                        
                                      </div>
                                      <div>{imageValidateS}</div>      
                                    </div>
                                    <div className="form-group text-right mt-3 mb-1">
                                      <Button type="submit" className="btn btn-pink mr-1 ml-1 w-15"
                                        disabled={isDiaryEmpty || !diaryMessageDetails
                                          || isValidating
                                          || !dirty
                                          || !!(errors.diray_status && touched.diray_status)
                                          || !!(errors.your_title && touched.your_title)
                                          || !!(errors.message && touched.message) || !getStudentDetails}
                                        value="Submit">Submit</Button>
                                    </div>
                                    <div>

                                    </div>
                                  </div>
                                </Form>
                              )
                            }}
                          />
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div>
                    {/* Here view diary details */}
                    <InfiniteScroll
                      dataLength={viewDiaryData.length}
                      next={this.fetchMoreDiaryData}
                      hasMore={this.state.hasMore}
                      loader={<h4 style={loadingDiary}>Loading...</h4>}
                    >
                      <>
                        {viewDiaryData.length > 0 && viewDiaryData !== undefined && viewDiaryData !== null && viewDiaryData?
                          <>
                            {
                              viewDiaryData.map((item: any, index: any) => (
                                <div className="card card-topline-red " key={index}>
                                  <div className="row p-4">
                                    <div className="col-md-10">

                                      <p>To:
                                        <>
                                          {item.class_list.map((classList: any, index: any) => (
                                            <>
                                              {classList.is_all ? (
                                                  <span className="btn btn-xs btn-circle btn-danger mr-1 ml-2 mb-2 cursorPoint titleCapitalize" key={index}>{classList.class_name}</span>
                                               ) : (
                                                  item.student_name_false.filter((student:any) => student.class_name === classList.class_name).slice(0,9).map((studentList:any, i:any) => (
                                                    <span className="btn btn-xs btn-circle btn-info mr-1 ml-2 mb-2 cursorPoint titleCapitalize" key={i}><span className="userNameTitle">{studentList.student_name}</span> ({studentList.class_name})</span>
                                                  )))
                                              }
                                            </>
                                          ))}                                          
                                        </>
                                        <div className="btn-group mr-1 ml-2 mb-2">
                                    {item.student_name_false.length > 10 && item.student_name_false !== null && item.student_name_false !== undefined ?
                                    <>
                                          <button className="btn  btn-xs btn-circle  deepPink-bgcolor btn-outline dropdown-toggle"
                                          data-toggle="dropdown">more
                                          <i className="fa fa-angle-down"></i>
                                      </button>
                                          <ul className="dropdown-menu">
                                            { item.student_name_false.slice(10).map((studentName: any, index: any) => (
                                                <li key={index} className="pl-2 pr-2 pt-1 titleCapitalize">{studentName.student_name}</li>
                                            ))}
                                            </ul>
                                            </>
                                            : null
                                          
                                        }
                                            </div>
                                      </p>
                                    </div>
                                    <div className="col-md-2 text-right">
                                      <button className="btn btn-circle btn-danger disabled m-b-10 cursorPoint">{item.diary_type}</button>
                                    </div>
                                    <div className="col-md-12">
                                      <h3 className="titleCapitalize color-pink"><strong>{item.title}</strong></h3>
                                      <hr className="mt-0" />
                                      <CKEditor
                                        editor={ClassicEditor}
                                        data={this.replaceDomain(item.message)}
                                        disabled={true} 
                                        config={{
                                          toolbar: [],
                                          link: {
                                            addTargetToExternalLinks: true,
                                          }
                                        }}
                                      />
                                      <div className="row">
                                        <div className="col-md-5">
                                          {item.images ?
                                            <img src={`${process.env.REACT_APP_API_URL}${item.images}`} className="w-100 p-3" alt="" />
                                            : null}
                                        </div>

                                      </div>
                                      <div className='row'>
                                          <div className='col-md-12'>
                                            {item.class_list && item.class_list.length > 0 && item.class_list.map((classItem:any)=>(
                                              classItem.student_list.find((student:any) => student.is_homework) ? (
                                                <div className='mt-4'>
                                                <Accordion key={classItem.class_id}>
                                                  <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon/>}
                                                    aria-controls='panel1a-content'
                                                    id='panel1a-header'
                                                    >
                                                    <div className='row' style={{ width: '100%'}}>
                                                      <div className='col-lg-3'>
                                                        <h5 className="btn btn-xs btn-circle btn-info mr-1 ml-2 mb-2 cursorPoint titleCapitalize">
                                                          <strong>{classItem.class_name}</strong>
                                                        </h5>
                                                      </div>
                                                      <div className='col-lg-3'>
                                                        <p> No of Students <strong>{classItem.no_of_assessments}</strong></p> 
                                                      </div>   
                                                      <div className='col-lg-3'>                                                       
                                                        <p> No of Completed <strong>{classItem.no_of_completed}</strong></p>
                                                      </div>
                                                      <div className='col-lg-3'>
                                                        <p> No of Incomplete <strong>{classItem.no_of_incompleted}</strong></p>
                                                      </div>
                                                    </div>
                                                  </AccordionSummary>
                                                  <AccordionDetails>
                                                    <div className="col-md-12">                                                  
                                                        {
                                                          classItem.student_list ?
                                                            classItem.student_list.map((items: any) => (
                                                              <>
                                                                <div className="mb-2 ml-3 mr-3">
                                                                  <div className="row replaybackground">
                                                                    <div className="col-md-12">
                                                                      <p className="mt-3">{items.reply}</p>
                                                                    </div>
                                                                    <div className='col-md-12'>
                                                                      <p className='mt-3'>
                                                                        {items.reply_date? (items.is_completed ? 'Completed' : "Incomplete") : "Not Yet Responded"}
                                                                        </p>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                      <div className="pull-right mb-3">

                                                                        <h5> Commented by <strong>{items.student_name}</strong>
                                                                        { items.reply_date &&                                                                    
                                                                         `${moment(items?.reply_date).fromNow()}`}</h5>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </>
                                                            ))
                                                            : null}
                                                      </div>
                                                  </AccordionDetails>
                                                </Accordion>
                                                </div>
                                              ) : []
                                              ))}
                                          </div>
                                        </div>         
                                    
                                    </div>
                                    <div className="col-md-12 p-3 text-right">
                                      Posted by <strong>{item.posted_by}</strong><span> {moment(item?.created_at).fromNow()}</span>
                                    </div>

                                    <div className="col-md-12">
                                      {
                                        item.is_homework == false &&item.reply ?
                                          item.reply.map((items: any) => (
                                            <>
                                              <div className="mb-2 ml-3 mr-3">
                                                <div className="row replaybackground">
                                                  <div className="col-md-12">
                                                    <p className="mt-3">{items.message}</p>
                                                  </div>
                                                  <div className='col-md-12'>
                                                    <p className='mt-3'>{items.no_of_assessments}</p>
                                                  </div>
                                                  <div className="col-md-12">
                                                    <div className="pull-right mb-3">
                                                      <h5>Commented by <strong>{items.username}</strong> {moment(items?.reply_date).fromNow()}</h5>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          ))
                                          : null}
                                    </div>
                                    <div className="col-md-12">
                                      {
                                        item.is_homework == true &&item.others_reply ?
                                          item.others_reply.map((items: any) => (
                                            <>
                                              <div className="mb-2 ml-3 mr-3">
                                                <div className="row replaybackground">
                                                  <div className="col-md-12">
                                                    <p className="mt-3">{items.message}</p>
                                                  </div>
                                                  <div className='col-md-12'>
                                                    <p className='mt-3'>{items.no_of_assessments}</p>
                                                  </div>
                                                  <div className="col-md-12">
                                                    <div className="pull-right mb-3">
                                                      <h5>Commented by <strong>{items.username}</strong> {moment(items?.reply_date).fromNow()}</h5>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          ))
                                          : null}
                                    </div>
                                    <div className="col-md-12">
                                      <Formik
                                        ref={node => this.formikReplyDiary = node}
                                        validationSchema={DiaryReplyData}
                                        initialValues={initialDiaryReply}
                                        onSubmit={(values: any, { setSubmitting, resetForm }) => {
                                          if (values) {
                                            let postValue: any = {
                                              id: item.id,
                                              reply: values.replayMessage
                                            }
                                            this.props.PutDiaryReplay(postValue).then((res: any) => {
                                              const { page, search, SortOrderData, OrderNameData, academicYear } = this.state;
                                              const postValue = {
                                                page_no: page,
                                                search: search,
                                                sort_by: SortOrderData,
                                                order_by: OrderNameData,
                                                academicYear: academicYear
                                              }
                                              this.props.fetchDiaryPost(postValue, DIARYAPI.DIARY).then((res:any)=>{
                                              this.getRecordData()
                                              })
                                            })
                                            resetForm();
                                          }

                                        }}
                                        render={({
                                          values, errors, isSubmitting, isValidating, dirty, touched, handleSubmit, setFieldValue
                                        }: FormikProps<any>) => {
                                          let validReplayMessage: any = (!values.replayMessage)
                                          return (
                                            <Form>
                                              <FormGroup>
                                                <Field
                                                  label='Reply Message'
                                                  type="text"
                                                  name="replayMessage"
                                                  component={TextField}
                                                  className="textfield__input"
                                                  disabled={false}
                                                />
                                              </FormGroup>
                                              {/* <textarea name="replayMessage" placeholder="Reply" className="form-control"></textarea> */}
                                              <div className="text-right pt-2">
                                                <input type="submit" className="btn btn-circle btn-pink m-b-10"
                                                  disabled={validReplayMessage
                                                    || isValidating
                                                    || !dirty
                                                    || !!(errors.replayMessage && touched.replayMessage)}
                                                  value="Reply" />
                                              </div>
                                            </Form>
                                          )
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>

                              ))
                            }
                          </>

                          : <CommonLoader/>}
                      </>
                    </InfiniteScroll>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={loadingDiary}><SpinnerLoader /></div>
        <div style={recordLoaderVisibility}><SpinnerLoader /></div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    getYear:state.profile.getYear,
		stopYear:state.profile.yearStatusFails,
    total: state.diary.total,
    per_page: state.diary.per_page,
    records: state.diary.records,
    page: state.diary.page,
    totalPage: state.diary.totalPage,
    loading: state.diary.loading,
    loadingStudent: state.student.loading,
    DiaryDetails: state.diary.items,
    AllClassList: state.diary.gradelist,
    AllStudentList: state.diary.standardList,
    getDiaryImageURL: state.student.ImageURL,
    getResponseClassList: state.diary.getClassList,
    getResponseStudent: state.diary.getStudentList,
    getSearchStudentList: state.diary.getSearchStudentList,
    errorMessage: state.diary.errors,
    getProfile: state.profile.profileData,
    getDropDown: state.diary.getDiaryDropDown,
    recordLoader:state.diary.recordLoader,
    isBoardError:state.diary.isBoardError

  };
};

export default connect(mapStateToProps, {
  fetchDiaryPost,
  fetchGetAllClassList,
  fetchGetStudent,
  addDiaryPost,
  fetchSearchAllStudent,
  DiaryDropDown,
  PutDiaryReplay,
  stopYearData,
  editorImageUpload
})(ViewDiaryDetails)
