export const option:any = {
	legend: {
		display: false
	},
	plugins: {
        datalabels: {
          display: true,
          color: "black",
          align: "end",
          anchor: "end",
          font: { size: "14" },
		  formatter: (val:any) => {
			return val + ' %';
		  },
        }
      },
	tooltips: {
		callbacks: {
			label: function (tooltipItem: any, data: any) {
				var dataset = data.datasets[tooltipItem.datasetIndex];
				var currentValue = dataset.data[tooltipItem.index];
				return '(' + currentValue + '%)';
			},
			title: function (tooltipItem: any, data: any) {
				return data.datasets[0].label[tooltipItem[0].index];
			}
		}
	},
	title: {
		display: true,
		text: 'Student Psychometric Report'
	},
	scales: {
		yAxes: [{
			ticks: {
			  beginAtZero: true,
			  steps: 10,
			  stepValue: 5,
			},
			gridLines : {
				color: 'rgba(170,170,170,1)',
				  lineWidth: 0.2
			}
		  }],
		xAxes: [{
			barPercentage: 0.4,
			gridLines: {
				display: false
			},
		}]
	}
}


export const overalloption:any = {
	legend: {
		display: false
	},
	plugins: {
        datalabels: {
          display: true,
          color: "black",
          align: "end",
          anchor: "end",
          font: { size: "14" },
		  formatter: (val:any) => {
			return val;
		  },
        }
      },
	tooltips: {
		callbacks: {
			label: function (tooltipItem: any, data: any) {
				var dataset = data.datasets[tooltipItem.datasetIndex];
				var currentValue = dataset.data[tooltipItem.index];
				return '(' + currentValue + ')';
			},
			title: function (tooltipItem: any, data: any) {
				return data.datasets[0].label[tooltipItem[0].index];
			}
		}
	},
	title: {
		display: false,
		text: 'Student Psychometric Report'
	},
	scales: {
		yAxes: [{
			ticks: {
			  beginAtZero: true,
			  steps: 10,
			  stepValue: 5
			},
			gridLines : {
				color: 'rgba(170,170,170,1)',
				  lineWidth: 0.2
			}
		  }],
		xAxes: [{
			barPercentage: 0.4,
			gridLines: {
				display: false
			}
		}]
	}
}

export const horizontalBaroption:any = {
	legend: {
		display: false
	},
	plugins: {
        datalabels: {
          display: true,
          color: "black",
          align: "end",
          anchor: "end",
          font: { size: "14" },
		  formatter: (val:any) => {
			return val != 0 ? val : "";
		  },
        }
      },
	tooltips: {
		callbacks: {	
			label: function (tooltipItem: any, data: any) {
				var dataset = data.datasets[tooltipItem.datasetIndex];
				var currentValue = dataset.data[tooltipItem.index];
				return '(' + currentValue + ')';
			},
			title: function (tooltipItem: any, data: any) {
				return data.datasets[0].label[tooltipItem[0].index];
			}
		}
	},
	title: {
		display: false,
		text: 'Student Psychometric Report'
	},
	scales: {
		x: {
			beginAtZero: true,
			min: 0, // Adjust if needed
			max: 100, // Adjust if needed
		  },
		yAxes: [{
			ticks: {
			  beginAtZero: true,
			  steps: 10,
			  stepValue: 5,
			max: 100, // Adjust if needed
			},
			gridLines : {
				color: 'rgba(170,170,170,1)',
				  lineWidth: 0.1
			}
		  }],
		xAxes: [{
			barPercentage: 0.4,
			gridLines: {
				color: 'rgba(170,170,170,1)',
				  lineWidth: 0.2
			},
			ticks: {
				beginAtZero: true,
				steps: 10,
				stepValue: 5,
			  	max: 120, // Adjust if needed
			  },
		}]
	}
}	


export const pieOption: any = {
	responsive: true,
	legend: {
		display: false
	},
	title: {
		display: false,
	},
	plugins: {
		datalabels: {
			display: true,
			color: "black",
			anchor: 'end',
			align: 'end',
			offset: 10,
			borderRadius: 25,
			font: { size: "14" },
			formatter: (val: any, label: any) => {
				return label.dataset.label[label.dataIndex];
			},
		}
	},
	layout: {
		padding: {
			bottom: 50,
			top: 50
		},
	},
	tooltips: {
		callbacks: {	
			label: function (tooltipItem: any, data: any) {
				var dataset = data.datasets[tooltipItem.datasetIndex];
				var currentValue = dataset.data[tooltipItem.index];
				return '(' + currentValue + '%)';
			},
			title: function (tooltipItem: any, data: any) {
				return data.datasets[0].label[tooltipItem[0].index];
			}
		}
	}
}

export const plugin:any  = {
	beforeInit: function (chart:any) {
	  // Get reference to the original fit function
	  const originalFit = chart.legend.fit
  
	  // Override the fit function
	  chart.legend.fit = function fit() {
		// Bind scope in order to use `this` correctly inside it
		originalFit.bind(chart.legend)()
		this.height += 40 // Change the height
	  }
	}
  }