import { Reducer } from 'redux'
import { SchoolsActionTypes, SchoolsState } from './Types'

export const initialState: SchoolsState = {
    items: [],
    loading: false,
    isAuthenticated: null,
    modelPop:false,
    errors: [],
    page: 1,
    per_page: 10,
    records: [],
    total: 0,
    getSchoolEdit:[],
    schoolCategoryList:null
}

const reducer: Reducer<SchoolsState> = (state = initialState, action) => {
    switch (action.type) {
        case SchoolsActionTypes.FETCH_SCHOOLS:
        case SchoolsActionTypes.ADD_SCHOOL:
        case SchoolsActionTypes.EDIT_SCHOOL:
        case SchoolsActionTypes.ADD_SCHOOL_LOGO:
        case SchoolsActionTypes.COMMON_GET:
            return { 
                ...state, 
                loading: true,
                isAuthenticated: false,
                errors:[],
                schoolCategoryList:null
            };
            case SchoolsActionTypes.SCHOOL_VIEW:
                return { 
                    ...state, 
                    loading: true,
                    isAuthenticated: false,
                    records: [],
                    errors:[]
                };
            case SchoolsActionTypes.FETCH_SCHOOL_ID:
                return { 
                    ...state, 
                    loading: true,
                    getSchoolEdit:[],
                    isAuthenticated: false,
                    errors:[]
                };
        case SchoolsActionTypes.ADD_SCHOOL_SUCCESS:
            return { 
                ...state, 
                loading: false,
                isAuthenticated: false,
                errors:null
            }; 
            case SchoolsActionTypes.COMMON_GET_SUCCESS:
                const { schoolCategoryList } = action
                return { 
                    ...state, 
                    loading: false,
                    isAuthenticated: false,
                    errors:null,
                    schoolCategoryList:schoolCategoryList
                };
        case SchoolsActionTypes.ADD_SCHOOL_LOGO_SUCCESS:
            return { 
                ...state, 
                loading: false,
                isAuthenticated: false,
                errors:null
            };  
        case SchoolsActionTypes.FETCH_SCHOOLS_SUCCESS:
        case SchoolsActionTypes.SCHOOL_VIEW_SUCCESS:
        const { payload, records, per_page, page, total } = action;
            if(page === 1) {
                state.records = []
            }           
            return {
                ...state, 
                loading: false, 
                items: payload,
                page: page,
                per_page: per_page,
                records: [...state.records, ...records],
                total: total,
                totalPage: Math.ceil(total / per_page),
                isAuthenticated: false,
            }
        case SchoolsActionTypes.FETCH_SCHOOL_SUCCESS_ID:   
        const { getSchoolEdit } = action;
            return {
                ...state, 
                loading: false, 
                items: action.payload,
                isAuthenticated: false, 
                modelPop: false ,
                getSchoolEdit: getSchoolEdit
            };
           
        case SchoolsActionTypes.DELETE_SCHOOL_SUCCESS:
                return {
                    ...state,
                    items: action.payload,
                    loading: false,
                    isAuthenticated: true
                };
        case SchoolsActionTypes.FETCH_SCHOOL_FAIL_ID:
        case SchoolsActionTypes.FETCH_SCHOOLS_FAIL:
        case SchoolsActionTypes.EDIT_SCHOOL_FAIL:
        case SchoolsActionTypes.DELETE_SCHOOL_FAIL:            
        case SchoolsActionTypes.ADD_SCHOOL_FAIL:            
        case SchoolsActionTypes.SCHOOL_VIEW_FAIL:
        case SchoolsActionTypes.COMMON_GET_FAIL:
        case SchoolsActionTypes.ADD_SCHOOL_LOGO_FAIL:
            return {
                ...state,
                errors: action.payload,
                loading: false,
                isAuthenticated: false,
                schoolCategoryList:null
            };
        case SchoolsActionTypes.Reset_School_Details:
            return initialState;
        case SchoolsActionTypes.CREATE_SCHOOL_CALENDAR_DEFAULT_DIARY:
        case SchoolsActionTypes.CREATE_SCHOOL_CALENDAR:
        case SchoolsActionTypes.GET_ALL_SCHOOL_CALENDAR:
        case SchoolsActionTypes.GET_SCHOOL_CALENDAR_BY_CALENDAR_ID:
        case SchoolsActionTypes.EDIT_SCHOOL_CALENDAR:
        case SchoolsActionTypes.DELETE_SCHOOL_CALENDAR:
        case SchoolsActionTypes.SCHOOL_CALENDAR_DOWNLOAD_EXCEL:
        case SchoolsActionTypes.SCHOOL_CALENDAR_UPLOAD_EXCEL:
            return{
                ...state,
                loading: true,
                isAuthenticated: false,
                errors: []
            }
        case SchoolsActionTypes.CREATE_SCHOOL_CALENDAR_DEFAULT_DIARY_SUCCESS:
            return{
                ...state,
                loading:false,
                isAuthenticated: false,
                errors: null,
                calendarDefaultEntry: action.payload
            }
        case SchoolsActionTypes.CREATE_SCHOOL_CALENDAR_SUCCESS:
            return{
                ...state,
                loading:false,
                isAuthenticated: false,
                errors:null,
                createdSchoolCalendarData: action.payload
            }
        case SchoolsActionTypes.GET_ALL_SCHOOL_CALENDAR_SUCCESS:
            return{
                ...state,
                loading: false,
                isAuthenticated: false,
                schoolcalendarData: action.payload
            }
        case SchoolsActionTypes.GET_SCHOOL_CALENDAR_BY_CALENDAR_ID_SUCCESS:
            return{
                ...state,
                loading: false,
                isAuthenticated: false,
                schoolCalendarDatabyId: action.payload
            }
        case SchoolsActionTypes.EDIT_SCHOOL_CALENDAR_SUCCESS:
            return{
                ...state,
                loading: false,
                isAuthenticated: false,
                editSchoolCalendarData: action.payload
            }
        case SchoolsActionTypes.DELETE_SCHOOL_CALENDAR_SUCCESS:
        case SchoolsActionTypes.SCHOOL_CALENDAR_DOWNLOAD_EXCEL_SUCCESS:
            return{
                ...state,
                loading: false,
                isAuthenticated: false,
        }
        case SchoolsActionTypes.SCHOOL_CALENDAR_UPLOAD_EXCEL_SUCCESS:
            return{
                ...state,
                loading: false,
                isAuthenticated: false,
                uploadSchoolCalendarData:action.payload
        }
        case SchoolsActionTypes.CREATE_SCHOOL_CALENDAR_DEFAULT_DIARY_FAIL:
        case SchoolsActionTypes.CREATE_SCHOOL_CALENDAR_FAIL:
        case SchoolsActionTypes.GET_ALL_SCHOOL_CALENDAR_FAIL:
        case SchoolsActionTypes.GET_SCHOOL_CALENDAR_BY_CALENDAR_ID_FAIL:
        case SchoolsActionTypes.EDIT_SCHOOL_CALENDAR_FAIL:
        case SchoolsActionTypes.DELETE_SCHOOL_CALENDAR_FAIL:
        case SchoolsActionTypes.SCHOOL_CALENDAR_DOWNLOAD_EXCEL_FAIL:
        case SchoolsActionTypes.SCHOOL_CALENDAR_UPLOAD_EXCEL_FAIL:
            return{
                ...state,
                loading: false,
                isAuthenticated: false,
                errors: action.payload
            }
        default:
            return state;
    }
}

export { reducer as schoolReducer }